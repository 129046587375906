import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
export const TemplateType = ({ templates, programData, setProgramData }) => {
  const [selectedType, setSelectedType] = useState(null);

  const isThemeSelected = (theme) => {
    if (selectedType) return selectedType._id === theme._id;
    return false;
  };

  const doMapSelection = () => {
    const targetType = templates.filter((theme) => {
      return theme.title === programData.programType;
    });
    if (targetType.length !== 1) {
      alert("Program type not found");
    } else {
      setSelectedType(targetType[0]);
    }
  };

  const doSetProgramData = (type) => {
    setProgramData({ ...programData, programType: type });
  };

  const doDisplayImage = () => {
    if (selectedType.imgs) {
      return selectedType.imgs.map((imgs) => {
        return (
          <img src={imgs} style={selectedType.size} className="mt-2 mr-1" />
        );
      });
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (programData.programType) {
      doMapSelection();
    }
  }, []);

  return (
    <div>
      <div>
        <h3>Select a template</h3>
      </div>
      <Row>
        <Col lg={6}>
          <Form.Group>
            {templates.map((template) => (
              <Form.Check
                type={"checkbox"}
                label={template.title}
                id={`disabled-default-${template.title.replace(/\s+/gi, "_")}`}
                key={`disabled-default-${template.title.replace(/\s+/gi, "_")}`}
                style={{ fontWeight: 700, fontSize: "1.5rem" }}
                className="mt-3 d-flex align-items-center"
                onChange={(e) => {
                  setSelectedType(template);
                  doSetProgramData(template.title);
                }}
                checked={isThemeSelected(template)}
              />
            ))}
          </Form.Group>
        </Col>
        <Col lg={6}>
          <div>{selectedType && doDisplayImage()}</div>
        </Col>
      </Row>
    </div>
  );
};
