import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import { globalFetch } from "../../components";

export const CreateUser = () => {
  const [doCreateAdmin, setDoCreateAdmin] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pass2, setPass2] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [funeralDate, setFuneralDate] = useState("");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const doCreateUser = async () => {
    const body = {
      name,
      email,
      password,
      phoneNumber,
      funeralDate,
      isAdmin: doCreateAdmin,
    };
    if (isFormValid()) {
      try {
        setIsLoading(true);
        await globalFetch("/api/users/new", "POST", body);
        await setSuccess("User created");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        resetForm();
      } catch (e) {
        setError(e.response.data.message);
      }
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPassword("");
    setPass2("");
    setPhoneNumber("");
    setFuneralDate("");
  };

  const isFormValid = () => {
    if (!name || !email || !password || !pass2) {
      setError("Please complete required fields");
      return false;
    } else {
      if (/\s+/.test(password)) {
        setError("Password cannot contain spaces");
        return false;
      }
      if (password !== pass2) {
        setError("Passwords must match");
        return false;
      }

      if (!doCreateAdmin) {
        if (!phoneNumber || !funeralDate) {
          setError("Please complete required fields");
          return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    setError("");
  }, [name, email, password, pass2, phoneNumber, funeralDate, doCreateAdmin]);

  return (
    <Container fluid>
      <Row>
        <Col lg={3}>
          <Form.Group style={{ textAlign: "center" }}>
            <Form.Check
              type={"checkbox"}
              label={`Funeral Home Employee`}
              id={`disabled-default-admin`}
              onChange={(e) => {
                setDoCreateAdmin(true);
              }}
              checked={doCreateAdmin}
            />
            {/* <Form.Check
              type={"checkbox"}
              label={`Base User`}
              id={`disabled-default-baseUser`}
              onChange={(e) => {
                setDoCreateAdmin(false);
              }}
              checked={!doCreateAdmin}
            /> */}
          </Form.Group>
        </Col>
        <Col lg={6} className="mr-auto">
          <div>
            <h1>Account Details</h1>
          </div>
          <div>
            <Form.Group controlId="name">
              <Form.Label> {doCreateAdmin ? "Name" : "Family Name"}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                required
                disabled={isLoading}
              />
            </Form.Group>
            <Form.Group controlId="emailAddress">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Email Address"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                disabled={isLoading}
                required
              />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder=""
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                disabled={isLoading}
                required
              />
            </Form.Group>
            <Form.Group controlId="password2">
              <Form.Label>Re-enter Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Re-enter Password"
                onChange={(e) => {
                  setPass2(e.target.value);
                }}
                value={pass2}
                disabled={isLoading}
                required
              />
            </Form.Group>
            {!doCreateAdmin && (
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  value={phoneNumber}
                  disabled={isLoading}
                  required
                />
              </Form.Group>
            )}
            {!doCreateAdmin && (
              <Form.Group controlId="funeralDate">
                <Form.Label>Funeral Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Funeral Date"
                  onChange={(e) => {
                    setFuneralDate(e.target.value);
                  }}
                  value={funeralDate}
                  disabled={isLoading}
                  required
                />
              </Form.Group>
            )}
            <Form.Group
              className="text-center"
              style={{ minHeight: 25, color: "red" }}
            >
              <p>{error}</p> <p style={{ color: "green" }}>{success}</p>
            </Form.Group>
            <Form.Group className="text-right">
              <Button onClick={doCreateUser}>Submit</Button>
            </Form.Group>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
