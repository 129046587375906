import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { onlyUnique } from "../../../components/utils";

export const ThemeSelect = ({ themes, programData, setProgramData }) => {
  const [selectedTheme, setSelectedTheme] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const mapCategories = () => {
    return themes.map((theme) => theme.category).filter(onlyUnique);
  };

  const [categories] = useState(mapCategories());

  const doMapSelection = () => {
    const targetTheme = themes.filter((theme) => {
      return theme.name === programData.programStyleTemplate;
    });
    if (targetTheme.length !== 1) {
      alert("Theme not found");
    } else {
      setSelectedCategory(targetTheme[0].category);
      setSelectedTheme(targetTheme[0]);
    }
  };

  const doSetProgramData = (theme) => {
    setProgramData({ ...programData, programStyleTemplate: theme });
  };

  const isThemeSelected = (theme) => {
    if (selectedTheme) return selectedTheme._id === theme._id;
    return false;
  };

  const doDisplayThemeImg = () => {
    if (selectedTheme.img) {
      const { img } = selectedTheme;
      return <img src={img} width={"100%"} className="mt-2 mr-1" />;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (programData.programStyleTemplate) {
      doMapSelection();
    }
  }, []);

  return (
    <div>
      <div>
        <h3>The theme sets the overall outline of the program</h3>
      </div>
      <Row>
        <Col lg={2}>
          <Form.Group>
            {categories.map((category) => (
              <Form.Check
                type={"checkbox"}
                label={category}
                id={`disabled-default-${category.replace(/\s+/gi, "_")}`}
                key={`disabled-default-${category.replace(/\s+/gi, "_")}`}
                style={{ fontWeight: 700, fontSize: "1.1rem" }}
                className="mt-3 d-flex align-items-center"
                onChange={(e) => {
                  setSelectedTheme(null);
                  setSelectedCategory(category);
                }}
                checked={category === selectedCategory}
              />
            ))}
          </Form.Group>
        </Col>
        <Col lg={3}>
          {selectedCategory && (
            <Form.Group>
              {themes
                .filter((theme) => theme.category === selectedCategory)
                .map((theme) => (
                  <Form.Check
                    type={"checkbox"}
                    label={theme.name}
                    id={`disabled-default-${theme.name.replace(/\s+/gi, "_")}`}
                    key={`disabled-default-${theme.name.replace(/\s+/gi, "_")}`}
                    style={{ fontWeight: 700, fontSize: "1.1rem" }}
                    className="mt-3 d-flex align-items-center"
                    onChange={(e) => {
                      setSelectedTheme(theme);
                      doSetProgramData(theme.name);
                    }}
                    checked={isThemeSelected(theme)}
                  />
                ))}
            </Form.Group>
          )}
        </Col>
        <Col lg={7}>{selectedTheme && doDisplayThemeImg()}</Col>
        <div>{/* //TODO add images */}</div>
      </Row>
    </div>
  );
};
