import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { globalFetch } from "../../../components";
import { UserContext } from "../../../context";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SelectImages = ({ templates, programData, setProgramData }) => {
  const userContext = useContext(UserContext);
  const [hasMainPhoto, setHasMainPhoto] = useState(false);
  const [hasSecondPhotos, setHasSecondPhotos] = useState(false);
  const [progId, setProgId] = useState(programData._id);
  const [isLoading, setIsLoading] = useState(false);

  const pictureLimitMap = {
    "Trifold Program Letter": 15,
    "Tabloid Booklet": 24,
    "Letter Booklet": 10,
    "Trifold Program Spread": 15,
    "Bifold Program Tabloid": 18,
  };

  const doHandleMainImage = async (file) => {
    let imageFormObj = new FormData();
    try {
      setIsLoading(true);
      imageFormObj.append("mainImage", file);

      const { data } = await globalFetch(
        `/api/programs/photos/${progId}`,
        "PATCH",
        imageFormObj
      );

      setProgramData({
        ...programData,
        mainPhoto: data,
      });

      setHasMainPhoto(true);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      alert("Error uploading file");
      setIsLoading(false);
    }
  };

  const doHandleSecondImage = async (file, idx) => {
    let imageFormObj = new FormData();
    try {
      setIsLoading(true);
      imageFormObj.append(`secondaryPhotos-${idx}`, file);
      const { data } = await globalFetch(
        `/api/programs/photos/${progId}`,
        "PATCH",
        imageFormObj
      );
      const { secondaryPhotos } = programData;
      secondaryPhotos[idx] = data;
      setProgramData({
        ...programData,
        secondaryPhotos: secondaryPhotos,
      });

      setHasSecondPhotos(true);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      alert("Error uploading file");
      setIsLoading(false);
    }
  };

  const doDownloadMainImg = () => {
    function _arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    try {
      const { data, imageName, contentType } = programData.mainPhoto;

      const base64Flag = `data:${contentType};base64,`;
      const url = base64Flag + _arrayBufferToBase64(data.data);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", imageName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      alert("error downloading photo");
      return;
    }
  };

  const doDownloadSecondImgs = (i) => {
    function _arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    try {
      const { data, imageName, contentType } = programData.secondaryPhotos[i];

      const base64Flag = `data:${contentType};base64,`;
      const url = base64Flag + _arrayBufferToBase64(data.data);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", imageName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      alert("error downloading photo");
      return;
    }
  };

  const doRemoveImageMainImg = async () => {
    const doRemove = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (doRemove) {
      setIsLoading(true);
      await globalFetch(`/api/programs/photos/${progId}`, "PATCH", {
        mainImageData: true,
      });
      setHasMainPhoto(false);
      setProgramData({
        ...programData,
        mainPhoto: null,
      });
      setIsLoading(false);
    }
  };

  const doRemoveImageSecondaryImg = async (idx) => {
    const doRemove = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (doRemove) {
      setIsLoading(true);
      await globalFetch(`/api/programs/photos/${progId}`, "PATCH", {
        secondaryPhotos: idx,
      });
      let { secondaryPhotos } = programData;
      secondaryPhotos.splice(idx, 1);
      setProgramData({
        ...programData,
        secondaryPhotos: secondaryPhotos,
      });
      if (secondaryPhotos.length > 0) {
        setHasSecondPhotos(true);
      } else {
        setHasSecondPhotos(false);
      }
      setIsLoading(false);
    }
  };

  const doGetPhotoCount = () => {
    return programData.programType &&
      pictureLimitMap.hasOwnProperty(programData.programType)
      ? `Secondary Photos (${pictureLimitMap[programData.programType]} Max)`
      : null;
  };

  const doDisplaySecondPhotosSection = () => {
    if (
      programData.programType &&
      pictureLimitMap.hasOwnProperty(programData.programType)
    ) {
      const { programType } = programData;
      const imgDivs = [];

      for (let i = 0; i < pictureLimitMap[programType]; i++) {
        imgDivs.push(
          <input
            type="file"
            className="mb-3"
            onChange={(e) => {
              doHandleSecondImage(e.target.files[0], i);
            }}
          />
        );
      }
      return <div>{imgDivs}</div>;
    }
    return null;
  };

  const doDisplaySecondPhotosSectionMulti = () => {
    const displays = [];
    programData.secondaryPhotos.forEach((photo, i) => {
      if (photo === null) {
        displays.push(
          <input
            type="file"
            className="mb-3"
            onChange={(e) => {
              doHandleSecondImage(e.target.files[0], i);
            }}
          />
        );
      } else {
        displays.push(
          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <h6>
                  <a
                    href={"javascript:void(0)"}
                    onClick={() => {
                      doDownloadSecondImgs(i);
                    }}
                  >
                    {photo?.imageName ? photo.imageName : "Name Unknown"}
                  </a>
                </h6>
                <div>
                  <Button
                    className="mdBtnFull ml-5"
                    onClick={() => {
                      doRemoveImageSecondaryImg(i);
                    }}
                  >
                    <FontAwesomeIcon
                      size={"xl"}
                      icon={faCancel}
                      id="navIcon"
                      className="mr-2"
                    />
                    Remove
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        );
      }
    });
    return displays;
  };

  useEffect(() => {
    setProgId(programData._id);
    setHasMainPhoto(programData.mainPhoto ? true : false);
    setHasSecondPhotos(
      programData.secondaryPhotos &&
        programData.secondaryPhotos.filter((photo) => photo !== null).length > 0
        ? true
        : false
    );
  }, [programData]);

  return (
    <div>
      <div>
        <h3>Select Photos</h3>
      </div>
      <div>
        <div>
          <h4>Main Photo</h4>
          {isLoading ? (
            <Row>
              <Col>
                <div className="d-flex align-items-center">
                  <h6 className="text-center">Loading</h6>
                  <div className="ml-3 spinner-grow text-warning"></div>
                  <div className="ml-3 spinner-grow text-warning"></div>
                  <div className="ml-3 spinner-grow text-warning"></div>
                </div>
              </Col>
            </Row>
          ) : (
            <div className="mb-3">
              {hasMainPhoto ? (
                <Row>
                  <Col>
                    <div className="d-flex align-items-center">
                      <h6>
                        <a
                          href={"javascript:void(0)"}
                          onClick={() => {
                            doDownloadMainImg();
                          }}
                        >
                          {programData.mainPhoto.imageName
                            ? programData.mainPhoto.imageName
                            : "Name Unknown"}
                        </a>
                      </h6>
                      <div>
                        <Button
                          className="mdBtnFull ml-5"
                          onClick={() => {
                            doRemoveImageMainImg();
                          }}
                        >
                          <FontAwesomeIcon
                            size={"xl"}
                            icon={faCancel}
                            id="navIcon"
                            className="mr-2"
                          />
                          Remove
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <input
                  type="file"
                  onChange={(e) => {
                    doHandleMainImage(e.target.files[0]);
                  }}
                />
              )}
            </div>
          )}
          <h4>{doGetPhotoCount()}</h4>
          {isLoading ? (
            <Row>
              <Col>
                <div className="d-flex align-items-center">
                  <h6 className="text-center">Loading</h6>
                  <div className="ml-3 spinner-grow text-warning"></div>
                  <div className="ml-3 spinner-grow text-warning"></div>
                  <div className="ml-3 spinner-grow text-warning"></div>
                </div>
              </Col>
            </Row>
          ) : (
            <div className="mb-3">
              {hasSecondPhotos
                ? doDisplaySecondPhotosSectionMulti()
                : doDisplaySecondPhotosSection()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
