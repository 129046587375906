import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
export const FontSelect = ({ fonts, programData, setProgramData }) => {
  const [selectedFont, setSelectedFont] = useState(null);

  const isThemeSelected = (font) => {
    if (selectedFont) return selectedFont._id === font._id;
    return false;
  };

  const doSetProgramData = (font) => {
    setProgramData({ ...programData, programFontType: font });
  };

  const doMapSelection = () => {
    const targetFont = fonts.filter((theme) => {
      return theme.title === programData.programFontType;
    });
    if (targetFont.length !== 1) {
      alert("Font not found");
    } else {
      setSelectedFont(targetFont[0]);
    }
  };

  useEffect(() => {
    if (programData.programFontType) {
      doMapSelection();
    }
  }, []);

  return (
    <div>
      <div>
        <h3>Select a font</h3>
      </div>
      <div>
        <div>
          <Form.Group>
            {fonts.map((font) => (
              <Form.Check
                type={"checkbox"}
                label={font.title}
                id={`disabled-default-${font.title.replace(/\s+/gi, "_")}`}
                key={`disabled-default-${font.title.replace(/\s+/gi, "_")}`}
                style={{
                  fontFamily: `${font.title}`,
                  fontWeight: 600,
                  fontSize: "1.5rem",
                }}
                className="mt-3 d-flex align-items-center"
                onChange={(e) => {
                  setSelectedFont(font);
                  doSetProgramData(font.title);
                }}
                checked={isThemeSelected(font)}
              />
            ))}
          </Form.Group>
        </div>
        <div></div>
      </div>
    </div>
  );
};
