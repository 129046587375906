import React from "react";
import "./style.css";
export const Loader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      id="basicLoader"
    >
      <h2 className="text-center">Loading</h2>
      <div className="ml-3 spinner-grow text-warning"></div>
      <div className="ml-3 spinner-grow text-warning"></div>
      <div className="ml-3 spinner-grow text-warning"></div>
    </div>
  );
};
