import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Button, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Loader, Nav } from "../../components";
import { decodeJWTBearer, getCookie } from "../../components/utils";
import { UserContext } from "../../context";
import "./style.css";
import {
  faHome,
  faUser,
  faUserGroup,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { globalFetch } from "../../components/utils/index";
import MaterialTable from "material-table";

export const Dashboard = () => {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isProgramsLoading, setIsProgramsLoading] = useState(true);
  const [programsData, setProgramsData] = useState([]);

  const navigation = useNavigate();

  const formatDates = (data) => {
    return data.map((prog) => {
      return {
        ...prog,
        funeralDate: prog.funeralDate
          ? new Date(prog.funeralDate).toUTCString().replace(/00:.*/gi, "")
          : "Not Set",
      };
    });
  };

  const fetchPrograms = async () => {
    try {
      setIsProgramsLoading(true);
      const { data } = await globalFetch("/api/programs");
      setProgramsData(formatDates(data));
      setIsProgramsLoading(false);
    } catch (e) {
      alert("Error fetching programs");
      navigation("/");
    }
  };

  const checkLoggedIn = async () => {
    if (!userContext.userData) {
      const programBuilderBearer = await getCookie("programBuilderBearer");
      if (programBuilderBearer) {
        try {
          const data = decodeJWTBearer(programBuilderBearer);
          await userContext.setUserData(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
          fetchPrograms();
        } catch (e) {
          navigation("/");
          //TODO Error Logout overloy
        }
      } else {
        //TODO  Logout overloy
        navigation("/");
      }
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      fetchPrograms();
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  const LeftNav = (
    <div id="LeftNav">
      <div id="welcome-sec">
        <h3>
          Welcome {userContext.userData ? userContext.userData.name : "...."}
        </h3>
      </div>
      <div id="btn-sec">
        <Button className={"nav-btn active"} variant="light">
          <FontAwesomeIcon size={"lg"} icon={faHome} id="navIcon" /> Dashboard
        </Button>
        <Button
          className={"nav-btn"}
          variant="light"
          onClick={() => {
            navigation("/createProgram");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={faPlus} id="navIcon" /> Create
          Program
        </Button>
        {/* <Button
          className={"nav-btn"}
          variant="light"
          onClick={() => {
            navigation("/account");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={faUser} id="navIcon" /> Account
        </Button> */}
        {/* <Button
          className={"nav-btn"}
          variant="light"
          onClick={() => {
            navigation("/manage-users");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={faUserGroup} id="navIcon" /> User
          Management
        </Button> */}
      </div>
    </div>
  );

  const ProgramTable = () => (
    <div id="ProgramTableComp">
      <div style={{ maxWidth: "100%", zIndex: 200 }}>
        {isProgramsLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: 300 }}
          >
            <span className="mr-3">Loading Program Table</span>
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <MaterialTable
            options={{
              headerStyle: {
                zIndex: 1,
              },
              search: false,
              pageSize: 7,
            }}
            columns={[
              { title: "Status", field: "status" },
              { title: "Name", field: "createByName" },
              { title: "Service Date", field: "funeralDate" },
              { title: "Funeral Home", field: "eventLocationName" },
            ]}
            data={programsData}
            title="Programs"
            onRowClick={(e, rowData) => {
              console.log(rowData);
              navigation(`/program/${rowData._id}`);
            }}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      {isLoading && <Loader />}
      <Nav path={"dashboard"} />
      <Container fluid>
        <Tabs
          defaultActiveKey="program-dashboard"
          id="uncontrolled-tab-example"
          className="mb-3 mt-5 pt-5"
          fill
        >
          <Tab eventKey="program-dashboard" title="Program Dashboard">
            <Row style={{ minHeight: "90vh" }}>
              <Col lg={3}>{LeftNav}</Col>
              <Col lg={9}>
                <ProgramTable />
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="restaurant-dashboard"
            title="Restaurant Menu Dashboard"
          >
            <h3>Proof of concept</h3>
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};
