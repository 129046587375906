import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  DropdownButton,
  Dropdown,
  Modal,
  Form,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";
import { globalFetch, Loader, Nav, ProgramView } from "../../components";
import "./style.css";
import { faHome, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Program = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [isLoadingProgram, setIsLoadingProgram] = useState(false);
  const [programData, setProgramData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [signature, setSignature] = useState("");
  const location = useLocation();
  const navigation = useNavigate();

  const statuses = [
    {
      title: "In Progress",
    },
    {
      title: "Draft Completed",
    },
    {
      title: "Program created",
    },
    {
      title: "Needs review from family",
    },
    {
      title: "Family approved",
    },
    {
      title: "Printed",
    },
  ];

  const fetchPrograms = async () => {
    try {
      setIsLoadingProgram(true);
      let { data } = await globalFetch(
        `/api/programs/${location.pathname.replace("/program/", "")}`
      );
      data = {
        ...data,
        mainPhoto: null,
        secondaryPhotos: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
      };
      setProgramData(data);
      setSelectedStatus({ title: data.status });
      setIsLoading(false);
      setIsLoadingProgram(false);
    } catch (e) {
      alert("Error fetching program");
      navigation("/");
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  const doUpdateStatus = async () => {
    setIsLoadingProgram(true);
    try {
      const { mainPhoto, secondaryPhotos, ...updatedProgramData } = programData;
      await globalFetch(`/api/programs/${programData._id}`, "PATCH", {
        ...updatedProgramData,
        status: selectedStatus.title,
        signatures: [
          ...updatedProgramData.signatures,
          { name: signature, date: new Date() },
        ],
      });
      setShow(false);
      fetchPrograms();
      setIsLoadingProgram(false);
    } catch (e) {
      setIsLoadingProgram(false);
      alert("error saving program");
    }
  };

  const doSave = async () => {
    setShow(true);
  };

  const LeftNav = (
    <div id="LeftNav">
      <div id="welcome-sec">
        <h6>
          Sevice Date:{" "}
          {programData && programData.funeralDate
            ? new Date(programData.funeralDate).toISOString().split("T")[0]
            : ""}
        </h6>
        <h6>
          Create Date:{" "}
          {programData
            ? new Date(programData.createDate).toISOString().split("T")[0]
            : ""}
        </h6>
        <h6>
          Last Update Date:{" "}
          {programData
            ? new Date(programData.lastUpdateDate).toISOString().split("T")[0]
            : ""}
        </h6>
        <div className="d-flex">
          <h5 className="mr-3">Status</h5>
          <DropdownButton
            variant={"warning"}
            id={"dropdown-menu-align-end-light-season"}
            className={"text-center mb-3"}
            title={selectedStatus ? selectedStatus.title : ""}
          >
            {[...statuses, { title: "Choose" }].map((season, idx) => (
              <Dropdown.Item
                eventKey="1"
                onClick={(e) => {
                  setSelectedStatus(season);
                }}
                id={season.title}
                key={`idx-${idx}`}
                disabled={season.title === "Choose"}
              >
                {season.title}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        <div>
          <Button
            onClick={() => {
              doSave();
            }}
            style={{ width: 100 }}
            variant={"warning"}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {isLoading && <Loader />}

      {!isLoading && programData && (
        <>
          <Nav path={"dashboard"} />
          <Container fluid style={{ marginTop: 100 }}>
            <Row>
              <Col lg={1}></Col>
              <Col lg={11}>
                <div className="d-flex align-items-center">
                  <Button
                    variant="light"
                    onClick={() => {
                      navigation("/dashboard");
                    }}
                  >
                    <FontAwesomeIcon size={"xl"} icon={faHome} id="navIcon" />
                  </Button>

                  <FontAwesomeIcon
                    className="ml-3 mr-3"
                    size={"xl"}
                    icon={faChevronRight}
                    id="navIcon"
                  />
                  <h3>Program</h3>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row style={{ minHeight: "100vh" }}>
              <Col lg={3}>{LeftNav}</Col>
              <Col lg={9}>
                {isLoadingProgram ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: 500 }}
                  >
                    <span className="mr-3">Loading Program</span>
                    <div class="spinner-border text-warning" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div id="ReviewComp">
                    <ProgramView
                      setProgramData={setProgramData}
                      programData={programData}
                      isPreview={false}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <Modal show={show} backdrop="static" size="lg">
            <Modal.Body style={{ backgroundColor: "white" }}>
              <div id="category-title-container">
                <h1 id="category-title">Signature</h1>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    size="4"
                    type="text"
                    placeholder=""
                    onChange={(e) => {
                      setSignature(e.target.value);
                    }}
                    value={signature}
                    disabled={isLoading}
                    required
                  />
                </Form.Group>
                <em>Date: {new Date().toDateString()}</em>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "white" }}>
              <Button
                className={"mr-5"}
                variant="light"
                onClick={() => {
                  setShow(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className={"saveBtn"}
                onClick={() => {
                  doUpdateStatus();
                }}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
};
