import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import { faPlus, faMinus, faCancel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { globalFetch } from "../utils";
import { UserContext } from "../../context";

export const ProgramView = ({
  setProgramData,
  programData,
  isPreview = true,
}) => {
  const [selectedEventLocation, setSelectedEventLocation] = useState(null);

  const eventLocations = [
    { title: "Gregory B. Levett and Sons Funeral Home & Crematory" },
    { title: "George W. Levett Sr. and Sons Funeral Home" },
    { title: "Young-Levett Funeral Home" },
    { title: "Leavitt Funeral Home" },
  ];
  const formatDates = (formData) => {
    let data = formData;
    if (formData.funeralDate) {
      const funeralDate = new Date(formData.funeralDate)
        .toISOString()
        .split("T")[0];
      data = { ...data, funeralDate };
    }
    return data;
  };
  const [textToAdd, setTextToAdd] = useState(formatDates({ ...programData }));
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMainPhoto, setHasMainPhoto] = useState(false);
  const [hasSecondPhotos, setHasSecondPhotos] = useState(false);
  const [progId, setProgId] = useState(programData._id);
  const [loadingPhotos, setLoadingPhotos] = useState(true);

  const pictureLimitMap = {
    "Trifold Program Letter": 15,
    "Tabloid Booklet": 24,
    "Letter Booklet": 10,
    "Trifold Program Spread": 15,
    "Bifold Program Tabloid": 18,
  };

  const doHandleMainImage = async (file) => {
    let imageFormObj = new FormData();
    try {
      setIsLoading(true);
      setLoadingPhotos(true);
      imageFormObj.append("mainImage", file);

      const { data } = await globalFetch(
        `/api/programs/photos/${progId}`,
        "PATCH",
        imageFormObj
      );
      setProgramData({
        ...programData,
        mainPhoto: data,
      });
      setHasMainPhoto(true);

      setIsLoading(false);
      setLoadingPhotos(false);
    } catch (e) {
      alert("Error uploading file");
      setIsLoading(false);
    }
  };

  const doDownloadMainImg = () => {
    function _arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    try {
      const { data, imageName, contentType } = programData.mainPhoto;

      const base64Flag = `data:${contentType};base64,`;
      const url = base64Flag + _arrayBufferToBase64(data.data);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", imageName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      alert("error downloading photo");
      return;
    }
  };

  const doRemoveImageMainImg = async () => {
    const doRemove = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (doRemove) {
      setIsLoading(true);
      setLoadingPhotos(true);
      const { data } = await globalFetch(
        `/api/programs/photos/${progId}`,
        "PATCH",
        {
          mainImageData: "remove",
        }
      );
      setHasMainPhoto(false);
      setProgramData({
        ...programData,
        mainPhoto: null,
      });
      setLoadingPhotos(false);
      setIsLoading(false);
    }
  };

  const doUpdateNestedProp = (key, nestedKey, value, idx) => {
    let targetKey = programData[key];
    if (!targetKey[idx]) {
      targetKey[idx] = { name: "", title: "" };
    }
    targetKey[idx][nestedKey] = value;
    setProgramData({ ...programData, [key]: targetKey });
  };

  const doSetTextToAdd = (key, value) => {
    setTextToAdd({ ...programData, [key]: value });
    setProgramData({ ...programData, [key]: value });
  };

  const addToNestedProp = (key) => {
    let targetKey = [...programData[key], { name: "", title: "" }];
    setTextToAdd({ ...programData, [key]: targetKey });
    setProgramData({ ...programData, [key]: targetKey });
  };

  const removeFromNestedProp = (key, idx) => {
    let targetKey = [...programData[key]];
    targetKey.splice(idx, 1);
    setTextToAdd({ ...programData, [key]: targetKey });
    setProgramData({ ...programData, [key]: targetKey });
  };

  const doUpdateProp = (key, value, idx) => {
    let targetKey = programData[key];
    if (!targetKey[idx]) {
      targetKey[idx] = "";
    }
    targetKey[idx] = value;
    setProgramData({ ...programData, [key]: targetKey });
  };

  const addToProp = (key) => {
    let targetKey = [...programData[key], ""];
    setProgramData({ ...programData, [key]: targetKey });
  };

  const removeFromProp = (key, idx) => {
    let targetKey = [...programData[key]];
    targetKey.splice(idx, 1);
    setProgramData({ ...programData, [key]: targetKey });
  };

  const doRemoveImageSecondaryImg = async (idx) => {
    const doRemove = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (doRemove) {
      setIsLoading(true);
      setLoadingPhotos(true);
      await globalFetch(`/api/programs/photos/${progId}`, "PATCH", {
        ...programData,
        mainPhoto: null,
        secondaryPhotos: idx,
      });
      let { secondaryPhotos } = programData;
      secondaryPhotos.splice(idx, 1);
      setProgramData({
        ...programData,
        secondaryPhotos: secondaryPhotos,
      });
      if (secondaryPhotos.length > 0) {
        setHasSecondPhotos(true);
      } else {
        setHasSecondPhotos(false);
      }
      setLoadingPhotos(false);
      setIsLoading(false);
    }
  };

  const doHandleSecondImage = async (file, idx) => {
    let imageFormObj = new FormData();
    try {
      setIsLoading(true);
      setLoadingPhotos(true);
      imageFormObj.append(`secondaryPhotos-${idx}`, file);

      const { data } = await globalFetch(
        `/api/programs/photos/${progId}`,
        "PATCH",
        imageFormObj
      );
      const { secondaryPhotos } = programData;
      secondaryPhotos[idx] = data;

      setProgramData({
        ...programData,
        secondaryPhotos: secondaryPhotos,
      });

      setHasSecondPhotos(true);
      setLoadingPhotos(false);
      setIsLoading(false);
    } catch (e) {
      alert("Error uploading file");
      setIsLoading(false);
    }
  };

  const doDownloadSecondImgs = (i) => {
    function _arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    try {
      const { data, imageName, contentType } = programData.secondaryPhotos[i];

      const base64Flag = `data:${contentType};base64,`;
      const url = base64Flag + _arrayBufferToBase64(data.data);
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", imageName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      alert("error downloading photo");
      return;
    }
  };

  const doGetPhotoCount = () => {
    return programData.programType &&
      pictureLimitMap.hasOwnProperty(programData.programType)
      ? `Secondary Photos (${pictureLimitMap[programData.programType]} Max)`
      : null;
  };

  const doDisplaySecondPhotosSectionMulti = () => {
    const displays = [];
    programData.secondaryPhotos.forEach((photo, i) => {
      if (photo === null) {
        displays.push(
          <input
            type="file"
            className="mb-3"
            onChange={(e) => {
              doHandleSecondImage(e.target.files[0], i);
            }}
          />
        );
      } else {
        displays.push(
          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <h6>
                  <a
                    href={"javascript:void(0)"}
                    onClick={() => {
                      doDownloadSecondImgs(i);
                    }}
                  >
                    {photo?.imageName ? photo.imageName : "Name Unknown"}
                  </a>
                </h6>
                <div>
                  <Button
                    className="mdBtnFull ml-5"
                    onClick={() => {
                      doRemoveImageSecondaryImg(i);
                    }}
                  >
                    <FontAwesomeIcon
                      size={"xl"}
                      icon={faCancel}
                      id="navIcon"
                      className="mr-2"
                    />
                    Remove
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        );
      }
    });
    return displays;
  };

  const doDisplaySecondPhotosSection = () => {
    if (
      programData.programType &&
      pictureLimitMap.hasOwnProperty(programData.programType)
    ) {
      const { programType } = programData;
      const imgDivs = [];

      for (let i = 0; i < pictureLimitMap[programType]; i++) {
        imgDivs.push(
          <input
            type="file"
            className="mb-3"
            onChange={(e) => {
              doHandleSecondImage(e.target.files[0], i);
            }}
          />
        );
      }
      return <div>{imgDivs}</div>;
    }
    return null;
  };

  const fetchPhotos = async () => {
    if (programData._id) {
      setLoadingPhotos(true);
      try {
        const { data } = await globalFetch(
          `/api/programs/photos/${programData._id}`
        );
        let { secondaryPhotos } = programData;
        console.log(programData);
        if (data.secondaryPhotos) {
          for (let i = 0; i < data.secondaryPhotos.length; i++) {
            if (data.secondaryPhotos[i]) {
              secondaryPhotos[i] = data.secondaryPhotos[i];
            }
          }
        }

        setProgramData({
          ...programData,
          mainPhoto: data.mainPhoto,
          secondaryPhotos: secondaryPhotos,
        });
      } catch (e) {
        console.log(e);
      }
    }
    setLoadingPhotos(false);
  };

  useEffect(() => {
    setTextToAdd(formatDates({ ...programData }));
    setSelectedEventLocation(programData.eventLocationName);
    setHasMainPhoto(programData.mainPhoto ? true : false);
    setHasSecondPhotos(
      programData.secondaryPhotos &&
        programData.secondaryPhotos.filter((photo) => photo !== null).length > 0
        ? true
        : false
    );
  }, [programData]);

  useEffect(() => {
    fetchPhotos();
  }, []);

  return (
    <div className="mb-5 pb-5">
      <Container>
        <Row>
          <Col lg={8}>
            <Form.Group controlId="funeralDate">
              <Form.Label>Service Date</Form.Label>
              <Form.Control
                type="date"
                placeholder=""
                onChange={(e) => {
                  doSetTextToAdd("funeralDate", e.target.value);
                }}
                value={textToAdd.funeralDate}
                required
              />
            </Form.Group>
            <Form.Group controlId="eventTime">
              <Form.Label>Event Time</Form.Label>
              <Form.Control
                type="time"
                placeholder=""
                onChange={(e) => {
                  doSetTextToAdd("eventTime", e.target.value);
                }}
                value={textToAdd.eventTime}
                required
              />
            </Form.Group>
            <Form.Group controlId="eventLocationName">
              <Form.Label>Funeral Home</Form.Label>
              <select
                value={
                  selectedEventLocation ? selectedEventLocation : "Choose..."
                }
                onChange={(e) => {
                  const val = e.target.value;
                  setSelectedEventLocation(val);
                  const title = val === "Choose..." ? "" : val;
                  doSetTextToAdd("eventLocationName", title);
                }}
                className="form-control"
              >
                {eventLocations &&
                  [{ title: "Choose..." }, ...eventLocations].map(
                    (eventLocation) => (
                      <option
                        value={eventLocation.title}
                        selected={eventLocation.title === "Choose..."}
                      >
                        {eventLocation.title}
                      </option>
                    )
                  )}
              </select>
            </Form.Group>
            <Form.Group controlId="servicesEntrustedTo">
              <Form.Label>Funeral Location</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doSetTextToAdd("servicesEntrustedTo", e.target.value);
                }}
                value={textToAdd.servicesEntrustedTo}
                required
              />
            </Form.Group>
            <Form.Group controlId="orderOfWorship">
              <Form.Label>Order Of Service</Form.Label>
              <TextareaAutosize
                onChange={(e) => {
                  doSetTextToAdd("orderOfWorship", e.target.value);
                }}
                id="reason"
                value={textToAdd.orderOfWorship}
                className="form-control"
                type="text"
                minRows={3}
              ></TextareaAutosize>
            </Form.Group>
            <Form.Group controlId="officiants">
              <Form.Label>Officiants</Form.Label>
              {textToAdd.officiants.map((officiant, idx) => (
                <Row className="d-flex align-items-center justify-content-center mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "officiants",
                          "name",
                          e.target.value,
                          idx
                        );
                      }}
                      value={officiant.name}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "officiants",
                          "title",
                          e.target.value,
                          idx
                        );
                      }}
                      value={officiant.title}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  {!isPreview && (
                    <Col>
                      <div className="pt-4">
                        {idx !== 0 && (
                          <Button
                            className={"saveBtn"}
                            onClick={() => {
                              removeFromNestedProp("officiants", idx);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"xl"}
                              icon={faMinus}
                              id="navIcon"
                            />
                          </Button>
                        )}
                        {textToAdd.officiants.length - 1 === idx &&
                          (textToAdd.officiants.length !== 0 || idx !== 0) && (
                            <Button
                              className={"saveBtn"}
                              onClick={() => {
                                addToNestedProp("officiants");
                              }}
                            >
                              <FontAwesomeIcon
                                size={"xl"}
                                icon={faPlus}
                                id="navIcon"
                              />
                            </Button>
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </Form.Group>
            <Form.Group controlId="processionals">
              <Form.Label>Processionals</Form.Label>
              {textToAdd.processionals.map((o, idx) => (
                <Row className="d-flex align-items-center justify-content-center mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "processionals",
                          "name",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.name}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "processionals",
                          "title",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.title}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  {!isPreview && (
                    <Col>
                      <div className="pt-4">
                        {idx !== 0 && (
                          <Button
                            className={"saveBtn"}
                            onClick={() => {
                              removeFromNestedProp("processionals", idx);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"xl"}
                              icon={faMinus}
                              id="navIcon"
                            />
                          </Button>
                        )}
                        {textToAdd.processionals.length - 1 === idx &&
                          (textToAdd.processionals.length !== 0 ||
                            idx !== 0) && (
                            <Button
                              className={"saveBtn"}
                              onClick={() => {
                                addToNestedProp("processionals");
                              }}
                            >
                              <FontAwesomeIcon
                                size={"xl"}
                                icon={faPlus}
                                id="navIcon"
                              />
                            </Button>
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </Form.Group>
            <Form.Group controlId="prayers">
              <Form.Label>Prayers</Form.Label>
              {textToAdd.prayers.map((o, idx) => (
                <Row className="d-flex align-items-center justify-content-center mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "prayers",
                          "name",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.name}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "prayers",
                          "title",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.title}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  {!isPreview && (
                    <Col>
                      <div className="pt-4">
                        {idx !== 0 && (
                          <Button
                            className={"saveBtn"}
                            onClick={() => {
                              removeFromNestedProp("prayers", idx);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"xl"}
                              icon={faMinus}
                              id="navIcon"
                            />
                          </Button>
                        )}
                        {textToAdd.prayers.length - 1 === idx &&
                          (textToAdd.prayers.length !== 0 || idx !== 0) && (
                            <Button
                              className={"saveBtn"}
                              onClick={() => {
                                addToNestedProp("prayers");
                              }}
                            >
                              <FontAwesomeIcon
                                size={"xl"}
                                icon={faPlus}
                                id="navIcon"
                              />
                            </Button>
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </Form.Group>
            <Form.Group controlId="scriptureReadings">
              <Form.Label>Scripture Readings</Form.Label>
              {textToAdd.scriptureReadings.map((o, idx) => (
                <Row className="d-flex align-items-center justify-content-center mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "scriptureReadings",
                          "name",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.name}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "scriptureReadings",
                          "title",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.title}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  {!isPreview && (
                    <Col>
                      <div className="pt-4">
                        {idx !== 0 && (
                          <Button
                            className={"saveBtn"}
                            onClick={() => {
                              removeFromNestedProp("scriptureReadings", idx);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"xl"}
                              icon={faMinus}
                              id="navIcon"
                            />
                          </Button>
                        )}
                        {textToAdd.scriptureReadings.length - 1 === idx &&
                          (textToAdd.scriptureReadings.length !== 0 ||
                            idx !== 0) && (
                            <Button
                              className={"saveBtn"}
                              onClick={() => {
                                addToNestedProp("scriptureReadings");
                              }}
                            >
                              <FontAwesomeIcon
                                size={"xl"}
                                icon={faPlus}
                                id="navIcon"
                              />
                            </Button>
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </Form.Group>
            <Form.Group controlId="tributesReflections">
              <Form.Label>Tributes Reflections</Form.Label>
              {textToAdd.tributesReflections.map((o, idx) => (
                <Row className="d-flex align-items-center justify-content-center mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "tributesReflections",
                          "name",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.name}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "tributesReflections",
                          "title",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.title}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  {!isPreview && (
                    <Col>
                      <div className="pt-4">
                        {idx !== 0 && (
                          <Button
                            className={"saveBtn"}
                            onClick={() => {
                              removeFromNestedProp("tributesReflections", idx);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"xl"}
                              icon={faMinus}
                              id="navIcon"
                            />
                          </Button>
                        )}
                        {textToAdd.tributesReflections.length - 1 === idx &&
                          (textToAdd.tributesReflections.length !== 0 ||
                            idx !== 0) && (
                            <Button
                              className={"saveBtn"}
                              onClick={() => {
                                addToNestedProp("tributesReflections");
                              }}
                            >
                              <FontAwesomeIcon
                                size={"xl"}
                                icon={faPlus}
                                id="navIcon"
                              />
                            </Button>
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </Form.Group>
            <Form.Group controlId="instrumentalTributes">
              <Form.Label>Instrumental Tributes</Form.Label>
              {textToAdd.instrumentalTributes.map((o, idx) => (
                <Row className="d-flex align-items-center justify-content-center mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "instrumentalTributes",
                          "name",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.name}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "instrumentalTributes",
                          "title",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.title}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  {!isPreview && (
                    <Col>
                      <div className="pt-4">
                        {idx !== 0 && (
                          <Button
                            className={"saveBtn"}
                            onClick={() => {
                              removeFromNestedProp("instrumentalTributes", idx);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"xl"}
                              icon={faMinus}
                              id="navIcon"
                            />
                          </Button>
                        )}
                        {textToAdd.instrumentalTributes.length - 1 === idx &&
                          (textToAdd.instrumentalTributes.length !== 0 ||
                            idx !== 0) && (
                            <Button
                              className={"saveBtn"}
                              onClick={() => {
                                addToNestedProp("instrumentalTributes");
                              }}
                            >
                              <FontAwesomeIcon
                                size={"xl"}
                                icon={faPlus}
                                id="navIcon"
                              />
                            </Button>
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </Form.Group>
            <Form.Group controlId="acknowledgments">
              <Form.Label>Acknowledgments</Form.Label>
              {textToAdd.acknowledgments.map((o, idx) => (
                <Row className="d-flex align-items-center justify-content-center mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "acknowledgments",
                          "name",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.name}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "acknowledgments",
                          "title",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.title}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  {!isPreview && (
                    <Col>
                      <div className="pt-4">
                        {idx !== 0 && (
                          <Button
                            className={"saveBtn"}
                            onClick={() => {
                              removeFromNestedProp("acknowledgments", idx);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"xl"}
                              icon={faMinus}
                              id="navIcon"
                            />
                          </Button>
                        )}
                        {textToAdd.acknowledgments.length - 1 === idx &&
                          (textToAdd.acknowledgments.length !== 0 ||
                            idx !== 0) && (
                            <Button
                              className={"saveBtn"}
                              onClick={() => {
                                addToNestedProp("acknowledgments");
                              }}
                            >
                              <FontAwesomeIcon
                                size={"xl"}
                                icon={faPlus}
                                id="navIcon"
                              />
                            </Button>
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </Form.Group>
            <Form.Group controlId="eulogies">
              <Form.Label>Eulogies</Form.Label>
              {textToAdd.eulogies.map((o, idx) => (
                <Row className="d-flex align-items-center justify-content-center mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "eulogies",
                          "name",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.name}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "eulogies",
                          "title",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.title}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  {!isPreview && (
                    <Col>
                      <div className="pt-4">
                        {idx !== 0 && (
                          <Button
                            className={"saveBtn"}
                            onClick={() => {
                              removeFromNestedProp("eulogies", idx);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"xl"}
                              icon={faMinus}
                              id="navIcon"
                            />
                          </Button>
                        )}
                        {textToAdd.eulogies.length - 1 === idx &&
                          (textToAdd.eulogies.length !== 0 || idx !== 0) && (
                            <Button
                              className={"saveBtn"}
                              onClick={() => {
                                addToNestedProp("eulogies");
                              }}
                            >
                              <FontAwesomeIcon
                                size={"xl"}
                                icon={faPlus}
                                id="navIcon"
                              />
                            </Button>
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </Form.Group>
            <Form.Group controlId="pallbearers">
              <Form.Label>Pallbearers</Form.Label>
              {textToAdd.pallbearers.map((o, idx) => (
                <Row className="d-flex align-items-center justify-content-center mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "pallbearers",
                          "name",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.name}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "pallbearers",
                          "title",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.title}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  {!isPreview && (
                    <Col>
                      <div className="pt-4">
                        {idx !== 0 && (
                          <Button
                            className={"saveBtn"}
                            onClick={() => {
                              removeFromNestedProp("pallbearers", idx);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"xl"}
                              icon={faMinus}
                              id="navIcon"
                            />
                          </Button>
                        )}
                        {textToAdd.pallbearers.length - 1 === idx &&
                          (textToAdd.pallbearers.length !== 0 || idx !== 0) && (
                            <Button
                              className={"saveBtn"}
                              onClick={() => {
                                addToNestedProp("pallbearers");
                              }}
                            >
                              <FontAwesomeIcon
                                size={"xl"}
                                icon={faPlus}
                                id="navIcon"
                              />
                            </Button>
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </Form.Group>
            <Form.Group controlId="honoraryPallbearers">
              <Form.Label>Honorary Pallbearers</Form.Label>
              {textToAdd.honoraryPallbearers.map((o, idx) => (
                <Row className="d-flex align-items-center justify-content-center mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "honoraryPallbearers",
                          "name",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.name}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  <Col>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        doUpdateNestedProp(
                          "honoraryPallbearers",
                          "title",
                          e.target.value,
                          idx
                        );
                      }}
                      value={o.title}
                      disabled={isLoading}
                      required
                    />
                  </Col>
                  {!isPreview && (
                    <Col>
                      <div className="pt-4">
                        {idx !== 0 && (
                          <Button
                            className={"saveBtn"}
                            onClick={() => {
                              removeFromNestedProp("honoraryPallbearers", idx);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"xl"}
                              icon={faMinus}
                              id="navIcon"
                            />
                          </Button>
                        )}
                        {textToAdd.honoraryPallbearers.length - 1 === idx &&
                          (textToAdd.honoraryPallbearers.length !== 0 ||
                            idx !== 0) && (
                            <Button
                              className={"saveBtn"}
                              onClick={() => {
                                addToNestedProp("honoraryPallbearers");
                              }}
                            >
                              <FontAwesomeIcon
                                size={"xl"}
                                icon={faPlus}
                                id="navIcon"
                              />
                            </Button>
                          )}
                      </div>
                    </Col>
                  )}
                </Row>
              ))}
            </Form.Group>
            <Form.Group controlId="orderOfWorship">
              <Form.Label>Obituary</Form.Label>
              <TextareaAutosize
                onChange={(e) => {
                  doSetTextToAdd("mainWriteup", e.target.value);
                }}
                id="reason"
                value={programData.mainWriteup}
                className="form-control"
                type="text"
                minRows={3}
              ></TextareaAutosize>
            </Form.Group>

            {/* {Font} */}
            <div>
              <h4>
                Font:
                {isPreview ? (
                  <em>{programData.programFontType}</em>
                ) : (
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={(e) => {
                      doSetTextToAdd("programFontType", e.target.value);
                    }}
                    value={textToAdd.programFontType}
                    required
                  />
                )}
              </h4>
            </div>
            {/* {Template} */}
            <div>
              <h4>
                Theme:{" "}
                {isPreview ? (
                  <em>{programData.programStyleTemplate}</em>
                ) : (
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={(e) => {
                      doSetTextToAdd("programStyleTemplate", e.target.value);
                    }}
                    value={textToAdd.programStyleTemplate}
                    required
                  />
                )}
              </h4>
            </div>
            {/* {Theme} */}
            <div>
              <h4>
                Template:{" "}
                {isPreview ? (
                  <em>{programData.programType}</em>
                ) : (
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={(e) => {
                      doSetTextToAdd("programType", e.target.value);
                    }}
                    value={textToAdd.programType}
                    required
                  />
                )}
              </h4>
            </div>
            {loadingPhotos ? (
              <div className="d-flex align-items-center">
                <h6 className="text-center">Photos Loading</h6>
                <div className="ml-3 spinner-grow text-warning"></div>
                <div className="ml-3 spinner-grow text-warning"></div>
                <div className="ml-3 spinner-grow text-warning"></div>
              </div>
            ) : (
              <>
                <div>
                  <h4>Main Photo</h4>
                  {hasMainPhoto ? (
                    <Row>
                      <Col>
                        <div className="d-flex align-items-center">
                          <h6>
                            <a
                              href={"javascript:void(0)"}
                              onClick={() => {
                                doDownloadMainImg();
                              }}
                            >
                              {programData.mainPhoto.imageName
                                ? programData.mainPhoto.imageName
                                : "Name Unknown"}
                            </a>
                          </h6>
                          <div>
                            <Button
                              className="mdBtnFull ml-5"
                              onClick={() => {
                                doRemoveImageMainImg();
                              }}
                            >
                              <FontAwesomeIcon
                                size={"xl"}
                                icon={faCancel}
                                id="navIcon"
                                className="mr-2"
                              />
                              Remove
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <input
                      type="file"
                      onChange={(e) => {
                        doHandleMainImage(e.target.files[0]);
                      }}
                    />
                  )}
                </div>
                <h4>{doGetPhotoCount()}</h4>
                <div className="mb-3">
                  {hasSecondPhotos
                    ? doDisplaySecondPhotosSectionMulti()
                    : doDisplaySecondPhotosSection()}
                </div>
              </>
            )}
          </Col>
          <Col>
            <div>
              <h5>Signatures</h5>
            </div>
            <div>
              {textToAdd.signatures.map((sig) => (
                <div
                  style={{
                    backgroundColor: "#eee",
                    marginBottom: 10,
                    padding: 5,
                    textAlign: "center",
                    borderRadius: 9,
                  }}
                >
                  <span>
                    Updated {new Date(sig.date).toISOString().split("T")[0]}
                  </span>
                  <br />
                  <span>
                    {new Date(sig.date)
                      .toLocaleTimeString()
                      .replace(/:\d{2} /gi, " ")}
                  </span>
                  <br />
                  <span>
                    <em>by {sig.name}</em>
                  </span>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
