import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { decodeJWTBearer, getCookie } from "../../components/utils";
import { UserContext } from "../../context";
import {
  faHome,
  faUser,
  faUserGroup,
  faPlus,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader, Nav, globalFetch } from "../../components";
import MaterialTable from "material-table";
import "./style.css";
import { CreateUser } from "../CreateUser/CreateUser";

export const UserManagement = () => {
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showCreateNewUser, setShowCreateNewUser] = useState(false);
  const [init, setInit] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const navigation = useNavigate();

  const formatLastUpdateDate = (data) => {
    return data.map((user) => {
      return {
        ...user,
        lastLoggedIn: new Date(user.lastLoggedIn).toDateString(),
      };
    });
  };

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const { data } = await globalFetch("/api/users");
      setUsersData(formatLastUpdateDate(data));
      setIsLoading(false);
    } catch (e) {
      alert("Error fetching users");
      navigation("/");
      //TODO Error Logout overloy
    }
  };

  const checkLoggedIn = async () => {
    if (!userContext.userData) {
      const programBuilderBearer = await getCookie("programBuilderBearer");
      if (programBuilderBearer) {
        try {
          const data = decodeJWTBearer(programBuilderBearer);
          await userContext.setUserData(data);
          fetchUsers();
          setInit(false);
        } catch (e) {
          navigation("/");
          //TODO Error Logout overloy
        }
      } else {
        //TODO  Logout overloy
        navigation("/");
      }
    } else {
      fetchUsers();
      setInit(false);
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  useEffect(() => {
    if (!showCreateNewUser && !init) {
      fetchUsers();
    }
  }, [showCreateNewUser]);

  const LeftNav = (
    <div id="LeftNav">
      <div id="welcome-sec">
        <h3>User Management</h3>
      </div>
      <div id="btn-sec">
        <Button
          className={"nav-btn"}
          variant="light"
          onClick={() => {
            navigation("/dashboard");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={faHome} id="navIcon" /> Dashboard
        </Button>
        {/* <Button
          className={"nav-btn"}
          variant="light"
          onClick={() => {
            navigation("/createProgram");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={faPlus} id="navIcon" /> Create
          Program
        </Button> */}
        {/* <Button
          className={"nav-btn"}
          variant="light"
          onClick={() => {
            navigation("/account");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={faUser} id="navIcon" /> Account
        </Button> */}
        {/* <Button
          className={"nav-btn active"}
          variant="light"
          onClick={() => {
            navigation("/manage-users");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={faUserGroup} id="navIcon" /> User
          Management
        </Button> */}
      </div>
    </div>
  );

  const SwitchBtns = (
    <div style={{ margin: "50px 0px" }}>
      <Button
        className={`nav-btn ${showCreateNewUser ? "" : "active"}`}
        variant="light"
        onClick={() => {
          setShowCreateNewUser(false);
        }}
      >
        User List
      </Button>
      <Button
        className={`nav-btn ${showCreateNewUser ? "active" : ""}`}
        variant="light"
        onClick={() => {
          setShowCreateNewUser(true);
        }}
      >
        <FontAwesomeIcon size={"lg"} icon={faPlus} id="navIcon" /> Create New
      </Button>
    </div>
  );

  const ProgramTable = () => (
    <div id="UserTableComp">
      {SwitchBtns}
      <div style={{ maxWidth: "100%", zIndex: 200 }}>
        <MaterialTable
          options={{
            headerStyle: {
              zIndex: 1,
            },
            search: false,
            pageSize: 7,
          }}
          columns={[
            { title: "Name", field: "name" },
            { title: "Email", field: "email" },
            { title: "Last Logged In", field: "lastLoggedIn" },
          ]}
          data={usersData}
          title="Users"
          onRowClick={(e, rowData) => {
            console.log(rowData);
          }}
        />
      </div>
    </div>
  );

  const CreateUserContainer = (
    <div id="CreateUserContainer">
      {SwitchBtns}
      <CreateUser />
    </div>
  );

  return (
    <>
      {isLoading && <Loader />}
      <Nav path={"dashboard"} />
      <Container fluid style={{ marginTop: 100 }}>
        <Row>
          <Col lg={1}></Col>
          <Col lg={11}>
            <div className="d-flex align-items-center">
              <Button
                variant="light"
                onClick={() => {
                  navigation("/dashboard");
                }}
              >
                <FontAwesomeIcon size={"xl"} icon={faHome} id="navIcon" />
              </Button>

              <FontAwesomeIcon
                className="ml-3 mr-3"
                size={"xl"}
                icon={faChevronRight}
                id="navIcon"
              />
              <h3>User Management</h3>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row style={{ minHeight: "100vh" }}>
          <Col lg={2}></Col>
          <Col lg={8}>
            {showCreateNewUser ? CreateUserContainer : <ProgramTable />}
          </Col>
          <Col lg={2}></Col>
        </Row>
      </Container>
    </>
  );
};
