import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import "./style.css";
import {
  FontSelect,
  TemplateType,
  ThemeSelect,
  SelectImages,
  AddText,
} from "./Selectors";
import { Loader, Nav, ProgramView } from "../../components";
import { faHome, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { UserContext } from "../../context";
import {
  decodeJWTBearer,
  getCookie,
  globalFetch,
} from "../../components/utils";

export const CreateProgram = () => {
  const [viewState, setViewState] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [signature, setSignature] = useState("");
  const userContext = useContext(UserContext);
  const [showSavedMessage, setShowSavedMessage] = useState(false);

  const [programData, setProgramData] = useState({
    _id: null,
    createById: "",
    createByName: "",
    createByEmail: "",
    funeralDate: "",
    programStyleTemplate: "",
    programFontType: "",
    programType: "",
    eventLocationName: "",
    servicesEntrustedTo: "",
    mainPhoto: null,
    secondaryPhotos: [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    eventTime: "",
    orderOfWorship: "",
    officiants: [{ name: "", title: "" }],
    processionals: [{ name: "", title: "" }],
    prayers: [{ name: "", title: "" }],
    scriptureReadings: [{ name: "", title: "" }],
    tributesReflections: [{ name: "", title: "" }],
    videoPres: false,
    instrumentalTributes: [{ name: "", title: "" }],
    acknowledgments: [{ name: "", title: "" }],
    eulogies: [{ name: "", title: "" }],
    mainWriteup: "",
    pallbearers: [{ name: "", title: "" }],
    honoraryPallbearers: [{ name: "", title: "" }],
    signatures: [],
  });

  const navigation = useNavigate();

  const checkLoggedIn = async () => {
    if (!userContext.userData) {
      const programBuilderBearer = await getCookie("programBuilderBearer");
      if (programBuilderBearer) {
        try {
          const data = decodeJWTBearer(programBuilderBearer);
          await userContext.setUserData(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        } catch (e) {
          navigation("/");
          //TODO Error Logout overloy
        }
      } else {
        //TODO  Logout overloy
        navigation("/");
      }
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  };

  const save = async (doSubmit = false) => {
    if (signature) {
      try {
        //must remove images or issues will occur with express
        const { mainPhoto, ...updatedProgramData } = programData;
        if (
          updatedProgramData.funeralDate === "" ||
          !updatedProgramData.funeralDate
        ) {
          alert("The Service Date required to save a Program");
          setShow(false);
          return;
        }
        if (programData._id) {
          const { data } = await globalFetch(
            `/api/programs/${programData._id}`,
            "PATCH",
            {
              ...updatedProgramData,
              signatures: [
                ...updatedProgramData.signatures,
                { name: signature, date: new Date() },
              ],
              status: doSubmit ? "Submitted" : "In Progress",
            }
          );
          setProgramData({ ...programData, ...data, _id: data._id });
        } else {
          const { email, _id, name } = userContext.userData;
          const { data } = await globalFetch("/api/programs", "POST", {
            ...updatedProgramData,
            createById: _id,
            createByName: name,
            createByEmail: email,
            signatures: [
              ...updatedProgramData.signatures,
              { name: signature, date: new Date() },
            ],
          });
          setProgramData({ ...programData, ...data, _id: data._id });
        }

        setShowSavedMessage(true);
        setShow(false);
        setTimeout(() => {
          setShowSavedMessage(false);
        }, 2000);
      } catch (e) {
        alert("Error Submitting form");
        setShow(false);
        setIsLoading(false);
        console.log(e);
      }
    }
  };

  const doSave = async () => {
    setShow(true);
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  const themes = [
    {
      category: "Military",
      name: "Any Branch of Service",
      _id: "1",
      img: process.env.PUBLIC_URL + "/Themes/military.png",
    },
    {
      category: "Greek Life",
      name: "Omega Man",
      _id: "2",
      img: process.env.PUBLIC_URL + "/Themes/greek/omega.png",
    },
    {
      category: "Greek Life",
      name: "Alpha Phi Alpha",
      _id: "3",
      img: process.env.PUBLIC_URL + "/Themes/greek/alphaphi.png",
    },
    {
      category: "Greek Life",
      name: "Alpha Kappa Alpha",
      _id: "4",
      img: process.env.PUBLIC_URL + "/Themes/greek/aka.png",
    },
    {
      category: "Greek Life",
      name: "Kappa Alpha Phi",
      _id: "5",
      img: process.env.PUBLIC_URL + "/Themes/greek/kapa.png",
    },
    {
      category: "Greek Life",
      name: "Delta Sigma Theta",
      _id: "6",
      img: process.env.PUBLIC_URL + "/Themes/greek/delta.png",
    },
    {
      category: "Greek Life",
      name: "Phi Beta Sigma",
      _id: "7",
      img: process.env.PUBLIC_URL + "/Themes/greek/phibeta.png",
    },
    {
      category: "Greek Life",
      name: "Zeta Phi Beta",
      _id: "8",
      img: process.env.PUBLIC_URL + "/Themes/greek/zeta.png",
    },
    {
      category: "Greek Life",
      name: "Sigma Gamma Rho",
      _id: "9",
      img: process.env.PUBLIC_URL + "/Themes/greek/sigma.png",
    },
    {
      category: "Greek Life",
      name: "Iota Phi Theta",
      _id: "10",
      img: process.env.PUBLIC_URL + "/Themes/greek/iota.png",
    },
    {
      category: "Sports",
      name: "Soccer",
      _id: "11",
      img: process.env.PUBLIC_URL + "/Themes/sports/kickingIt.png",
    },
    {
      category: "Sports",
      name: "Football",
      _id: "12",
      img: process.env.PUBLIC_URL + "/Themes/sports/football.png",
    },
    {
      category: "Sports",
      name: "Basketball",
      _id: "13",
      img: process.env.PUBLIC_URL + "/Themes/sports/basketBall.png",
    },
    {
      category: "Sports",
      name: "Baseball",
      _id: "14",
      img: process.env.PUBLIC_URL + "/Themes/sports/baseball.png",
    },
    {
      category: "Sports",
      name: "Golf",
      _id: "15",
      img: process.env.PUBLIC_URL + "/Themes/sports/18thhole.png",
    },
    {
      category: "Nature",
      name: "Enduring",
      _id: "16",
      img: process.env.PUBLIC_URL + "/Themes/nature/enduring.png",
    },
    {
      category: "Nature",
      name: "Field of Dreams",
      _id: "17",
      img: process.env.PUBLIC_URL + "/Themes/nature/fieldOfDreams.png",
    },
    {
      category: "Nature",
      name: "Bright Floral",
      _id: "18",
      img: process.env.PUBLIC_URL + "/Themes/nature/floral.png",
    },
    {
      category: "Nature",
      name: "Ray of Flowers",
      _id: "19",
      img: process.env.PUBLIC_URL + "/Themes/nature/flowers.png",
    },
    {
      category: "Nature",
      name: "Footprints in the Sand",
      _id: "20",
      img: process.env.PUBLIC_URL + "/Themes/nature/footprintsinsand.png",
    },
    {
      category: "Nature",
      name: "Green Meadows",
      _id: "21",
      img: process.env.PUBLIC_URL + "/Themes/nature/greenMedow.png",
    },
    {
      category: "Nature",
      name: "Lighthouse",
      _id: "22",
      img: process.env.PUBLIC_URL + "/Themes/nature/lightHouse.png",
    },
    {
      category: "Nature",
      name: "Light Rose",
      _id: "23",
      img: process.env.PUBLIC_URL + "/Themes/nature/lightRose.png",
    },
    {
      category: "Nature",
      name: "Mountain Top",
      _id: "24",
      img: process.env.PUBLIC_URL + "/Themes/nature/mountainTop.png",
    },
    {
      category: "Nature",
      name: "Natural Green",
      _id: "25",
      img: process.env.PUBLIC_URL + "/Themes/nature/naturalGreen.png",
    },
    {
      category: "Nature",
      name: "Pink Rose",
      _id: "26",
      img: process.env.PUBLIC_URL + "/Themes/nature/pinkRose.png",
    },
    {
      category: "Nature",
      name: "Sand Dunes",
      _id: "27",
      img: process.env.PUBLIC_URL + "/Themes/nature/sandduns.png",
    },
    {
      category: "Nature",
      name: "Sweet Orchids",
      _id: "28",
      img: process.env.PUBLIC_URL + "/Themes/nature/sweetOrchers.png",
    },
    {
      category: "Nature",
      name: "Tip Toe Throught the Tulips",
      _id: "29",
      img: process.env.PUBLIC_URL + "/Themes/nature/tipToe.png",
    },
    {
      category: "Nature",
      name: "A Walk on the Beach",
      _id: "30",
      img: process.env.PUBLIC_URL + "/Themes/nature/walkthebeach.png",
    },
    {
      category: "Nature",
      name: "Yellow Blossom",
      _id: "31",
      img: process.env.PUBLIC_URL + "/Themes/nature/yellowBlossem.png",
    },
  ];

  const fonts = [
    {
      title: "Times New Roman",
      _id: 1,
    },
    {
      title: "Georgia",
      _id: 2,
    },
    {
      title: "Garamond",
      _id: 3,
    },
    {
      title: "Snell Roundhand, cursive",
      _id: 10,
    },
    {
      title: "Arial",
      _id: 4,
    },
    {
      title: "Verdana",
      _id: 5,
    },
    {
      title: "Helvetica",
      _id: 6,
    },
    {
      title: "Courier New",
      _id: 7,
    },
    {
      title: "Copperplate",
      _id: 8,
    },
    {
      title: "Brush Script MT, cursive",
      _id: 9,
    },
    {
      title: "URW Chancery L, cursive",
      _id: 11,
    },
  ];

  const templates = [
    {
      title: "Thank You Card",
      _id: "1",
      imgs: [
        process.env.PUBLIC_URL + "/TemplateImgs/1.jpg",
        process.env.PUBLIC_URL + "/TemplateImgs/2.jpg",
      ],
      size: { width: "100%" },
    },
    {
      title: "Book Mark",
      _id: "2",
      imgs: [
        process.env.PUBLIC_URL + "/Bookmark/1.jpg",
        process.env.PUBLIC_URL + "/Bookmark/2.jpg",
      ],
      size: { width: "15rem", height: "39rem" },
    },
    {
      title: "Trifold Program Letter",
      _id: "3",
      imgs: [
        process.env.PUBLIC_URL + "/TrifoldLetter/1.jpg",
        process.env.PUBLIC_URL + "/TrifoldLetter/2.jpg",
      ],
      size: { width: "100%" },
    },
    {
      title: "Prayer Card",
      _id: "4",
      imgs: [
        process.env.PUBLIC_URL + "/PrayerCard/1.jpg",
        process.env.PUBLIC_URL + "/PrayerCard/2.jpg",
      ],
      size: { width: "15rem", height: "30rem" },
    },
    {
      title: "Tabloid Booklet",
      _id: "5",
      imgs: [
        process.env.PUBLIC_URL + "/TabloidBook/1.jpg",
        process.env.PUBLIC_URL + "/TabloidBook/2.jpg",
        process.env.PUBLIC_URL + "/TabloidBook/3.jpg",
        process.env.PUBLIC_URL + "/TabloidBook/4.jpg",
        process.env.PUBLIC_URL + "/TabloidBook/5.jpg",
        process.env.PUBLIC_URL + "/TabloidBook/6.jpg",
        process.env.PUBLIC_URL + "/TabloidBook/7.jpg",
        process.env.PUBLIC_URL + "/TabloidBook/8.jpg",
      ],
      size: { width: "100%" },
    },
    {
      title: "Letter Booklet",
      _id: "6",
      imgs: [
        process.env.PUBLIC_URL + "/LetterBook/1.jpg",
        process.env.PUBLIC_URL + "/LetterBook/2.jpg",
        process.env.PUBLIC_URL + "/LetterBook/3.jpg",
        process.env.PUBLIC_URL + "/LetterBook/4.jpg",
        process.env.PUBLIC_URL + "/LetterBook/5.jpg",
        process.env.PUBLIC_URL + "/LetterBook/6.jpg",
        process.env.PUBLIC_URL + "/LetterBook/7.jpg",
        process.env.PUBLIC_URL + "/LetterBook/8.jpg",
      ],
      size: { width: "100%" },
    },
    {
      title: "Bifold Program Letter",
      _id: "7",
      imgs: [
        process.env.PUBLIC_URL + "/BifoldLetter/1.jpg",
        process.env.PUBLIC_URL + "/BifoldLetter/2.jpg",
        process.env.PUBLIC_URL + "/BifoldLetter/3.jpg",
      ],
      size: { width: "15rem", height: "30rem" },
    },
    {
      title: "Trifold Program Spread",
      _id: "8",
      imgs: [
        process.env.PUBLIC_URL + "/TrifoldSpread/1.jpg",
        process.env.PUBLIC_URL + "/TrifoldSpread/2.jpg",
      ],
      size: { width: "100%" },
    },
    {
      title: "Bifold Program Tabloid",
      _id: "9",
      imgs: [
        process.env.PUBLIC_URL + "/BifoldTabloid/1.jpg",
        process.env.PUBLIC_URL + "/BifoldTabloid/2.jpg",
        process.env.PUBLIC_URL + "/BifoldTabloid/3.jpg",
        process.env.PUBLIC_URL + "/BifoldTabloid/4.jpg",
      ],
      size: { width: "15rem", height: "30rem" },
    },
  ];

  const getBtnClass = (targetState) => {
    return targetState <= viewState ? "mdBtnFull mdBtn" : "mdBtnEmpty mdBtn";
  };

  const doSetViewState = (targetState) => {
    // need to save initially before continuing
    if (!programData._id) {
      alert("Please save before continuing");
      return;
    }
    setViewState(targetState);
  };

  const LeftBtnContainer = () => (
    <div className="d-flex flex-column">
      <div className="d-flex">
        <Button
          className={getBtnClass(1)}
          onClick={() => {
            doSetViewState(1);
          }}
        >
          Add Text
        </Button>
        {viewState === 1 && (
          <Button
            className={"saveBtn"}
            onClick={() => {
              doSave();
            }}
          >
            Save
          </Button>
        )}
      </div>
      <div className="d-flex">
        <Button
          className={getBtnClass(2)}
          onClick={() => {
            doSetViewState(2);
          }}
        >
          Choose a Theme
        </Button>
        {viewState === 2 && (
          <Button
            className={"saveBtn"}
            onClick={() => {
              doSave();
            }}
          >
            Save
          </Button>
        )}
      </div>
      <div className="d-flex">
        <Button
          className={getBtnClass(3)}
          onClick={() => {
            doSetViewState(3);
          }}
        >
          Font
        </Button>
        {viewState === 3 && (
          <Button
            className={"saveBtn"}
            onClick={() => {
              doSave();
            }}
          >
            Save
          </Button>
        )}
      </div>
      <div className="d-flex">
        <Button
          className={getBtnClass(4)}
          onClick={() => {
            doSetViewState(4);
          }}
        >
          Template Type
        </Button>
        {viewState === 4 && (
          <Button
            className={"saveBtn"}
            onClick={() => {
              doSave();
            }}
          >
            Save
          </Button>
        )}
      </div>
      <div className="d-flex">
        <Button
          className={getBtnClass(5)}
          onClick={() => {
            doSetViewState(5);
          }}
        >
          Upload Photos
        </Button>
        {viewState === 5 && (
          <Button
            className={"saveBtn"}
            onClick={() => {
              doSave();
            }}
          >
            Save
          </Button>
        )}
      </div>
      <div className="d-flex">
        <Button
          className={getBtnClass(6)}
          onClick={() => {
            doSetViewState(6);
          }}
        >
          Review
        </Button>
        {viewState === 6 && (
          <Button className={"saveBtn"} onClick={() => {}}>
            Save
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      {isLoading && <Loader />}
      <Nav path={"dashboard"} />
      <Container fluid style={{ marginTop: 100 }}>
        <Row>
          <Col lg={1}></Col>
          <Col lg={11}>
            <div className="d-flex align-items-center">
              <Button
                variant="light"
                onClick={() => {
                  navigation("/dashboard");
                }}
              >
                <FontAwesomeIcon size={"xl"} icon={faHome} id="navIcon" />
              </Button>

              <FontAwesomeIcon
                className="ml-3 mr-3"
                size={"xl"}
                icon={faChevronRight}
                id="navIcon"
              />
              <h3>Create a Program</h3>
            </div>
          </Col>
        </Row>
      </Container>

      <Container style={{ marginTop: 10 }}>
        <Row>
          <Col className="text-center" style={{ height: 50 }}>
            {showSavedMessage && (
              <div className="d-flex justify-content-center align-items-center">
                <div className="mr-3 spinner-grow text-success"></div>
                <h2 className="text-center">Saved</h2>
                <div className="ml-3 spinner-grow text-success"></div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Container fluid id="CreateProgram-Page" style={{ marginBottom: 50 }}>
        <Row>
          <Col lg={3} className="d-flex justify-content-center">
            <LeftBtnContainer />
          </Col>
          <Col lg={8} className="mr-auto">
            {/* <MainContentContainer /> */}
            {viewState === 1 && (
              <AddText
                templates={templates}
                programData={programData}
                setProgramData={setProgramData}
              />
            )}
            {viewState === 2 && (
              <ThemeSelect
                themes={themes}
                programData={programData}
                setProgramData={setProgramData}
              />
            )}
            {viewState === 3 && (
              <FontSelect
                fonts={fonts}
                programData={programData}
                setProgramData={setProgramData}
              />
            )}
            {viewState === 4 && (
              <TemplateType
                templates={templates}
                programData={programData}
                setProgramData={setProgramData}
              />
            )}
            {viewState === 5 && (
              <SelectImages
                templates={templates}
                programData={programData}
                setProgramData={setProgramData}
              />
            )}
            {viewState === 6 && (
              <ProgramView
                programData={programData}
                setProgramData={setProgramData}
              />
            )}
          </Col>
        </Row>
      </Container>
      <Modal show={show} backdrop="static" size="lg">
        {/* <Modal.Header>
        <Modal.Title>Select your </Modal.Title>
      </Modal.Header> */}
        <Modal.Body style={{ backgroundColor: "white" }}>
          <div id="category-title-container">
            <h1 id="category-title">Signature</h1>
            <p>Note: You have 72 hours to submit this program</p>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                size="4"
                type="text"
                placeholder=""
                onChange={(e) => {
                  setSignature(e.target.value);
                }}
                value={signature}
                disabled={isLoading}
                required
              />
            </Form.Group>
            <em>Date: {new Date().toDateString()}</em>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "white" }}>
          <Button
            className={"mr-5"}
            variant="light"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className={"saveBtn"}
            onClick={() => {
              save();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
