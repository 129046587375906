import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AddText = ({ programData, setProgramData }) => {
  const [textToAdd, setTextToAdd] = useState({
    funeralDate: "",
    eventLocationName: "",
    servicesEntrustedTo: "",
    eventTime: "",
    orderOfWorship: "",
    officiants: [{ name: "", title: "" }],
    processionals: [{ name: "", title: "" }],
    prayers: [{ name: "", title: "" }],
    scriptureReadings: [{ name: "", title: "" }],
    tributesReflections: [{ name: "", title: "" }],
    instrumentalTributes: [""],
    acknowledgments: [""],
    eulogies: [""],
    mainWriteup: "",
    pallbearers: [{ name: "", title: "" }],
    honoraryPallbearers: [{ name: "", title: "" }],
  });

  const [selectedEventLocation, setSelectedEventLocation] = useState(null);

  const eventLocations = [
    { title: "Gregory B. Levett and Sons Funeral Home & Crematory" },
    { title: "George W. Levett Sr. and Sons Funeral Home" },
    { title: "Young-Levett Funeral Home" },
    { title: "Leavitt Funeral Home" },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const doSetTextToAdd = (key, val) => {
    setTextToAdd({ ...textToAdd, [key]: val });
    setProgramData({ ...programData, [key]: val });
  };

  const doUpdateNestedProp = (key, nestedKey, value, idx) => {
    let targetKey = programData[key];
    if (!targetKey[idx]) {
      targetKey[idx] = { name: "", title: "" };
    }
    targetKey[idx][nestedKey] = value;
    setProgramData({ ...programData, [key]: targetKey });
  };

  const addToNestedProp = (key) => {
    let targetKey = [...programData[key], { name: "", title: "" }];
    setProgramData({ ...programData, [key]: targetKey });
  };

  const removeFromNestedProp = (key, idx) => {
    let targetKey = [...programData[key]];
    targetKey.splice(idx, 1);
    setProgramData({ ...programData, [key]: targetKey });
  };

  const doUpdateProp = (key, value, idx) => {
    let targetKey = programData[key];
    if (!targetKey[idx]) {
      targetKey[idx] = "";
    }
    targetKey[idx] = value;
    setProgramData({ ...programData, [key]: targetKey });
  };

  const addToProp = (key) => {
    let targetKey = [...programData[key], ""];
    setProgramData({ ...programData, [key]: targetKey });
  };

  const removeFromProp = (key, idx) => {
    let targetKey = [...programData[key]];
    targetKey.splice(idx, 1);
    setProgramData({ ...programData, [key]: targetKey });
  };

  const formatDates = (formData) => {
    let data = formData;
    if (formData.funeralDate) {
      const funeralDate = new Date(formData.funeralDate)
        .toISOString()
        .split("T")[0];
      data = { ...data, funeralDate };
    }
    return data;
  };

  useEffect(() => {
    setSelectedEventLocation(programData.eventLocationName);
    setTextToAdd(formatDates({ ...textToAdd, ...programData }));
  }, [programData]);

  return (
    <div className="mb-5 pb-5">
      <Form.Group controlId="funeralDate">
        <Form.Label>Service Date</Form.Label>
        <Form.Control
          type="date"
          placeholder=""
          onChange={(e) => {
            doSetTextToAdd("funeralDate", e.target.value);
          }}
          value={textToAdd.funeralDate}
          disabled={isLoading}
          required
        />
      </Form.Group>
      <Form.Group controlId="eventTime">
        <Form.Label>Event Time</Form.Label>
        <Form.Control
          type="time"
          placeholder=""
          onChange={(e) => {
            doSetTextToAdd("eventTime", e.target.value);
          }}
          value={textToAdd.eventTime}
          disabled={isLoading}
          required
        />
      </Form.Group>
      <Form.Group controlId="eventLocationName">
        <Form.Label>Funeral Home</Form.Label>
        <select
          value={selectedEventLocation ? selectedEventLocation : "Choose..."}
          onChange={(e) => {
            const val = e.target.value;
            setSelectedEventLocation(val);
            const title = val === "Choose..." ? "" : val;
            doSetTextToAdd("eventLocationName", title);
          }}
          className="form-control"
        >
          {eventLocations &&
            [{ title: "Choose..." }, ...eventLocations].map((eventLocation) => (
              <option
                value={eventLocation.title}
                selected={eventLocation.title === "Choose..."}
              >
                {eventLocation.title}
              </option>
            ))}
        </select>
      </Form.Group>

      <Form.Group controlId="servicesEntrustedTo">
        <Form.Label>Funeral Location</Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          onChange={(e) => {
            doSetTextToAdd("servicesEntrustedTo", e.target.value);
          }}
          value={textToAdd.servicesEntrustedTo}
          disabled={isLoading}
          required
        />
      </Form.Group>
      <Form.Group controlId="orderOfWorship">
        <Form.Label>Order Of Service</Form.Label>
        <TextareaAutosize
          onChange={(e) => {
            doSetTextToAdd("orderOfWorship", e.target.value);
          }}
          id="reason"
          value={textToAdd.orderOfWorship}
          className="form-control"
          type="text"
          minRows={3}
        ></TextareaAutosize>
      </Form.Group>
      <Form.Group controlId="officiants">
        <Form.Label>Officiants</Form.Label>
        {textToAdd.officiants.map((officiant, idx) => (
          <Row className="d-flex align-items-center justify-content-center mb-3">
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp("officiants", "name", e.target.value, idx);
                }}
                value={officiant.name}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "officiants",
                    "title",
                    e.target.value,
                    idx
                  );
                }}
                value={officiant.title}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <div className="pt-4">
                {idx !== 0 && (
                  <Button
                    className={"saveBtn"}
                    onClick={() => {
                      removeFromNestedProp("officiants", idx);
                    }}
                  >
                    <FontAwesomeIcon size={"xl"} icon={faMinus} id="navIcon" />
                  </Button>
                )}
                {textToAdd.officiants.length - 1 === idx &&
                  (textToAdd.officiants.length !== 0 || idx !== 0) && (
                    <Button
                      className={"saveBtn"}
                      onClick={() => {
                        addToNestedProp("officiants");
                      }}
                    >
                      <FontAwesomeIcon size={"xl"} icon={faPlus} id="navIcon" />
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
        ))}
      </Form.Group>
      <Form.Group controlId="processionals">
        <Form.Label>Processionals</Form.Label>
        {textToAdd.processionals.map((o, idx) => (
          <Row className="d-flex align-items-center justify-content-center mb-3">
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "processionals",
                    "name",
                    e.target.value,
                    idx
                  );
                }}
                value={o.name}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "processionals",
                    "title",
                    e.target.value,
                    idx
                  );
                }}
                value={o.title}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <div className="pt-4">
                {idx !== 0 && (
                  <Button
                    className={"saveBtn"}
                    onClick={() => {
                      removeFromNestedProp("processionals", idx);
                    }}
                  >
                    <FontAwesomeIcon size={"xl"} icon={faMinus} id="navIcon" />
                  </Button>
                )}
                {textToAdd.processionals.length - 1 === idx &&
                  (textToAdd.processionals.length !== 0 || idx !== 0) && (
                    <Button
                      className={"saveBtn"}
                      onClick={() => {
                        addToNestedProp("processionals");
                      }}
                    >
                      <FontAwesomeIcon size={"xl"} icon={faPlus} id="navIcon" />
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
        ))}
      </Form.Group>
      <Form.Group controlId="prayers">
        <Form.Label>Prayers</Form.Label>
        {textToAdd.prayers.map((o, idx) => (
          <Row className="d-flex align-items-center justify-content-center mb-3">
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp("prayers", "name", e.target.value, idx);
                }}
                value={o.name}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp("prayers", "title", e.target.value, idx);
                }}
                value={o.title}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <div className="pt-4">
                {idx !== 0 && (
                  <Button
                    className={"saveBtn"}
                    onClick={() => {
                      removeFromNestedProp("prayers", idx);
                    }}
                  >
                    <FontAwesomeIcon size={"xl"} icon={faMinus} id="navIcon" />
                  </Button>
                )}
                {textToAdd.prayers.length - 1 === idx &&
                  (textToAdd.prayers.length !== 0 || idx !== 0) && (
                    <Button
                      className={"saveBtn"}
                      onClick={() => {
                        addToNestedProp("prayers");
                      }}
                    >
                      <FontAwesomeIcon size={"xl"} icon={faPlus} id="navIcon" />
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
        ))}
      </Form.Group>
      <Form.Group controlId="scriptureReadings">
        <Form.Label>Scripture Readings</Form.Label>
        {textToAdd.scriptureReadings.map((o, idx) => (
          <Row className="d-flex align-items-center justify-content-center mb-3">
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "scriptureReadings",
                    "name",
                    e.target.value,
                    idx
                  );
                }}
                value={o.name}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "scriptureReadings",
                    "title",
                    e.target.value,
                    idx
                  );
                }}
                value={o.title}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <div className="pt-4">
                {idx !== 0 && (
                  <Button
                    className={"saveBtn"}
                    onClick={() => {
                      removeFromNestedProp("scriptureReadings", idx);
                    }}
                  >
                    <FontAwesomeIcon size={"xl"} icon={faMinus} id="navIcon" />
                  </Button>
                )}
                {textToAdd.scriptureReadings.length - 1 === idx &&
                  (textToAdd.scriptureReadings.length !== 0 || idx !== 0) && (
                    <Button
                      className={"saveBtn"}
                      onClick={() => {
                        addToNestedProp("scriptureReadings");
                      }}
                    >
                      <FontAwesomeIcon size={"xl"} icon={faPlus} id="navIcon" />
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
        ))}
      </Form.Group>
      <Form.Group controlId="tributesReflections">
        <Form.Label>Tributes Reflections</Form.Label>
        {textToAdd.tributesReflections.map((o, idx) => (
          <Row className="d-flex align-items-center justify-content-center mb-3">
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "tributesReflections",
                    "name",
                    e.target.value,
                    idx
                  );
                }}
                value={o.name}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "tributesReflections",
                    "title",
                    e.target.value,
                    idx
                  );
                }}
                value={o.title}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <div className="pt-4">
                {idx !== 0 && (
                  <Button
                    className={"saveBtn"}
                    onClick={() => {
                      removeFromNestedProp("tributesReflections", idx);
                    }}
                  >
                    <FontAwesomeIcon size={"xl"} icon={faMinus} id="navIcon" />
                  </Button>
                )}
                {textToAdd.tributesReflections.length - 1 === idx &&
                  (textToAdd.tributesReflections.length !== 0 || idx !== 0) && (
                    <Button
                      className={"saveBtn"}
                      onClick={() => {
                        addToNestedProp("tributesReflections");
                      }}
                    >
                      <FontAwesomeIcon size={"xl"} icon={faPlus} id="navIcon" />
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
        ))}
      </Form.Group>
      <Form.Group controlId="instrumentalTributes">
        <Form.Label>Instrumental Tributes</Form.Label>
        {textToAdd.instrumentalTributes.map((o, idx) => (
          <Row className="d-flex align-items-center justify-content-center mb-3">
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "instrumentalTributes",
                    "name",
                    e.target.value,
                    idx
                  );
                }}
                value={o.name}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "instrumentalTributes",
                    "title",
                    e.target.value,
                    idx
                  );
                }}
                value={o.title}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <div className="pt-4">
                {idx !== 0 && (
                  <Button
                    className={"saveBtn"}
                    onClick={() => {
                      removeFromNestedProp("instrumentalTributes", idx);
                    }}
                  >
                    <FontAwesomeIcon size={"xl"} icon={faMinus} id="navIcon" />
                  </Button>
                )}
                {textToAdd.instrumentalTributes.length - 1 === idx &&
                  (textToAdd.instrumentalTributes.length !== 0 ||
                    idx !== 0) && (
                    <Button
                      className={"saveBtn"}
                      onClick={() => {
                        addToNestedProp("instrumentalTributes");
                      }}
                    >
                      <FontAwesomeIcon size={"xl"} icon={faPlus} id="navIcon" />
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
        ))}
      </Form.Group>
      <Form.Group controlId="acknowledgments">
        <Form.Label>Acknowledgments</Form.Label>
        {textToAdd.acknowledgments.map((o, idx) => (
          <Row className="d-flex align-items-center justify-content-center mb-3">
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "acknowledgments",
                    "name",
                    e.target.value,
                    idx
                  );
                }}
                value={o.name}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "acknowledgments",
                    "title",
                    e.target.value,
                    idx
                  );
                }}
                value={o.title}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <div className="pt-4">
                {idx !== 0 && (
                  <Button
                    className={"saveBtn"}
                    onClick={() => {
                      removeFromNestedProp("acknowledgments", idx);
                    }}
                  >
                    <FontAwesomeIcon size={"xl"} icon={faMinus} id="navIcon" />
                  </Button>
                )}
                {textToAdd.acknowledgments.length - 1 === idx &&
                  (textToAdd.acknowledgments.length !== 0 || idx !== 0) && (
                    <Button
                      className={"saveBtn"}
                      onClick={() => {
                        addToNestedProp("acknowledgments");
                      }}
                    >
                      <FontAwesomeIcon size={"xl"} icon={faPlus} id="navIcon" />
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
        ))}
      </Form.Group>
      <Form.Group controlId="eulogies">
        <Form.Label>Eulogies</Form.Label>
        {textToAdd.eulogies.map((o, idx) => (
          <Row className="d-flex align-items-center justify-content-center mb-3">
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp("eulogies", "name", e.target.value, idx);
                }}
                value={o.name}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp("eulogies", "title", e.target.value, idx);
                }}
                value={o.title}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <div className="pt-4">
                {idx !== 0 && (
                  <Button
                    className={"saveBtn"}
                    onClick={() => {
                      removeFromNestedProp("eulogies", idx);
                    }}
                  >
                    <FontAwesomeIcon size={"xl"} icon={faMinus} id="navIcon" />
                  </Button>
                )}
                {textToAdd.eulogies.length - 1 === idx &&
                  (textToAdd.eulogies.length !== 0 || idx !== 0) && (
                    <Button
                      className={"saveBtn"}
                      onClick={() => {
                        addToNestedProp("eulogies");
                      }}
                    >
                      <FontAwesomeIcon size={"xl"} icon={faPlus} id="navIcon" />
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
        ))}
      </Form.Group>
      <Form.Group controlId="pallbearers">
        <Form.Label>Pallbearers</Form.Label>
        {textToAdd.pallbearers.map((o, idx) => (
          <Row className="d-flex align-items-center justify-content-center mb-3">
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "pallbearers",
                    "name",
                    e.target.value,
                    idx
                  );
                }}
                value={o.name}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "pallbearers",
                    "title",
                    e.target.value,
                    idx
                  );
                }}
                value={o.title}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <div className="pt-4">
                {idx !== 0 && (
                  <Button
                    className={"saveBtn"}
                    onClick={() => {
                      removeFromNestedProp("pallbearers", idx);
                    }}
                  >
                    <FontAwesomeIcon size={"xl"} icon={faMinus} id="navIcon" />
                  </Button>
                )}
                {textToAdd.pallbearers.length - 1 === idx &&
                  (textToAdd.pallbearers.length !== 0 || idx !== 0) && (
                    <Button
                      className={"saveBtn"}
                      onClick={() => {
                        addToNestedProp("pallbearers");
                      }}
                    >
                      <FontAwesomeIcon size={"xl"} icon={faPlus} id="navIcon" />
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
        ))}
      </Form.Group>
      <Form.Group controlId="honoraryPallbearers">
        <Form.Label>Honorary Pallbearers</Form.Label>
        {textToAdd.honoraryPallbearers.map((o, idx) => (
          <Row className="d-flex align-items-center justify-content-center mb-3">
            <Col>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "honoraryPallbearers",
                    "name",
                    e.target.value,
                    idx
                  );
                }}
                value={o.name}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => {
                  doUpdateNestedProp(
                    "honoraryPallbearers",
                    "title",
                    e.target.value,
                    idx
                  );
                }}
                value={o.title}
                disabled={isLoading}
                required
              />
            </Col>
            <Col>
              <div className="pt-4">
                {idx !== 0 && (
                  <Button
                    className={"saveBtn"}
                    onClick={() => {
                      removeFromNestedProp("honoraryPallbearers", idx);
                    }}
                  >
                    <FontAwesomeIcon size={"xl"} icon={faMinus} id="navIcon" />
                  </Button>
                )}
                {textToAdd.honoraryPallbearers.length - 1 === idx &&
                  (textToAdd.honoraryPallbearers.length !== 0 || idx !== 0) && (
                    <Button
                      className={"saveBtn"}
                      onClick={() => {
                        addToNestedProp("honoraryPallbearers");
                      }}
                    >
                      <FontAwesomeIcon size={"xl"} icon={faPlus} id="navIcon" />
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
        ))}
      </Form.Group>
      <Form.Group controlId="orderOfWorship">
        <Form.Label>Obituary</Form.Label>
        <TextareaAutosize
          onChange={(e) => {
            doSetTextToAdd("mainWriteup", e.target.value);
          }}
          id="reason"
          value={textToAdd.mainWriteup}
          className="form-control"
          type="text"
          minRows={3}
        ></TextareaAutosize>
      </Form.Group>
    </div>
  );
};
