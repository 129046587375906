import React from "react";
import { Button, Container, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router";
import { deleteCookie } from "../utils";
import "./style.css";
import { faUser, faUserGroup, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Nav = ({ path }) => {
  const navigation = useNavigate();

  const doShowLogOut = () => {
    const needsOut = ["dashboard"];
    return needsOut.includes(path);
  };

  const doLogout = () => {
    deleteCookie("programBuilderBearer");
    navigation("/");
  };

  return (
    <Navbar expand="lg" bg="dark" sticky="top" id="Nav-Comp">
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            alt="logo"
            className="logo"
            src={process.env.PUBLIC_URL + "/images/logoTrans.png"}
          />
        </Navbar.Brand>

        {path !== "/afroverseCalendar" && (
          <>
            <Navbar.Toggle id="mainNavToggle" onClick={() => {}}>
              <FontAwesomeIcon
                color="#ffc109"
                size={"lg"}
                icon={faBars}
                id="navIcon"
              />
            </Navbar.Toggle>
            <Navbar.Collapse className="justify-content-end">
              <div className="NavDropDownItemContainer">
                {doShowLogOut() && (
                  <div className="navDropDownItem">
                    <Button
                      variant="light"
                      onClick={() => {
                        navigation("/account");
                      }}
                    >
                      <FontAwesomeIcon size={"lg"} icon={faUser} id="navIcon" />
                    </Button>
                  </div>
                )}
              </div>
              <div className="NavDropDownItemContainer">
                {doShowLogOut() && (
                  <div className="navDropDownItem">
                    <Button
                      variant="light"
                      onClick={() => {
                        navigation("/manage-users");
                      }}
                    >
                      <FontAwesomeIcon
                        size={"lg"}
                        icon={faUserGroup}
                        id="navIcon"
                      />
                      {/* User Management */}
                    </Button>
                  </div>
                )}
              </div>
              <div className="NavDropDownItemContainer">
                {doShowLogOut() && (
                  <div className="navDropDownItem">
                    <Button
                      variant="light"
                      onClick={() => {
                        doLogout();
                      }}
                    >
                      Sign out
                    </Button>
                  </div>
                )}
              </div>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};
