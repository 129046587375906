import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { globalFetch } from "../../components";
import { UserContext } from "../../context";
import "./style.css";
import { useNavigate } from "react-router";
import { decodeJWTBearer, getCookie, setCookie } from "../../components/utils";

export const Home = () => {
  const userContext = useContext(UserContext);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigation = useNavigate();

  const doSignIn = async () => {
    const body = {
      email,
      password,
    };
    if (isFormValid()) {
      try {
        setIsLoading(true);
        const { data, headers } = await globalFetch("/api/users", "POST", body);
        await userContext.setUserData(data);
        setCookie("programBuilderBearer", headers.authorization);
        navigation("/dashboard");
      } catch (e) {
        setError(e.response.data.message);
        setIsLoading(false);
      }
    }
  };

  const isFormValid = () => {
    if (!email || !password) {
      setError("Please complete required fields");
      return false;
    }
    return true;
  };

  useEffect(() => {
    setError("");
  }, [email, password]);

  const checkAuthCookie = async () => {
    const programBuilderBearer = await getCookie("programBuilderBearer");
    if (programBuilderBearer) {
      try {
        const data = decodeJWTBearer(programBuilderBearer);
        await userContext.setUserData(data);
        navigation("/dashboard");
      } catch (e) {
        console.log("");
      }
    }
  };

  useEffect(() => {
    checkAuthCookie();
  }, []);

  const LogInForm = (
    <div id="LogInFormContainer">
      <div id="LogInForm">
        <div style={{ textAlign: "center" }}>
          <img
            alt="logo"
            width={"50%"}
            src={process.env.PUBLIC_URL + "/images/logoTrans.png"}
          />
        </div>
        <Form.Group controlId="emailAddress">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email Address"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            defaultValue={email}
            disabled={isLoading}
            required
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder=""
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            defaultValue={password}
            disabled={isLoading}
            required
          />
        </Form.Group>
        <Form.Group
          className="text-center"
          style={{ minHeight: 25, color: "red" }}
        >
          <p>{error}</p>
        </Form.Group>
        <Form.Group className="text-right">
          <Button variant="warning" onClick={doSignIn}>
            Sign In
          </Button>
        </Form.Group>
      </div>
    </div>
  );

  return <div id="Home-Page">{LogInForm}</div>;
};
