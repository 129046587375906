import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Loader, Nav, globalFetch } from "../../components";
import { decodeJWTBearer, getCookie, setCookie } from "../../components/utils";
import { UserContext } from "../../context";
import {
  faHome,
  faUser,
  faUserGroup,
  faPlus,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.css";

export const EditAccount = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pass2, setPass2] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [funeralDate, setFuneralDate] = useState("");
  const [doResetPassword, setDoResetPassword] = useState(false);

  const [error, setError] = useState("");
  const userContext = useContext(UserContext);
  const navigation = useNavigate();

  const doPatchUser = async () => {
    const body = doResetPassword
      ? {
          name,
          email,
          password,
          phoneNumber,
          funeralDate,
        }
      : {
          name,
          email,
          phoneNumber,
        };
    if (isFormValid()) {
      try {
        setIsLoading(true);

        const { data, headers } = await globalFetch(
          `/api/users/${userContext.userData._id}`,
          "PATCH",
          body
        );
        setCookie("programBuilderBearer", headers.authorization);
        userContext.setUserData(data);
        setFields(data);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      } catch (e) {
        //TODO handle error
        setError(e.response.data.message);
        console.log(e);
      }
    }
  };

  const setFields = (data) => {
    setIsAdmin(data.isAdmin);
    setName(data.name);
    setEmail(data.email);
    setPhoneNumber(data.phoneNumber);
    setFuneralDate(data.funeralDate);
  };

  const isFormValid = () => {
    if (doResetPassword) {
      if (!name || !email || !password || !pass2) {
        setError("Please complete required fields");
        return false;
      } else {
        if (/\s+/.test(password)) {
          setError("Password cannot contain spaces");
          return false;
        }
        if (password !== pass2) {
          setError("Passwords must match");
          return false;
        }

        if (!isAdmin) {
          if (!phoneNumber || !funeralDate) {
            setError("Please complete required fields");
            return false;
          }
        }
      }
      return true;
    } else {
      if (!name || !email) {
        setError("Please complete required fields");
        return false;
      } else {
        if (!isAdmin) {
          if (!phoneNumber || !funeralDate) {
            setError("Please complete required fields");
            return false;
          }
        }
      }
      return true;
    }
  };

  const checkLoggedIn = async () => {
    if (!userContext.userData) {
      const programBuilderBearer = await getCookie("programBuilderBearer");
      if (programBuilderBearer) {
        try {
          const data = decodeJWTBearer(programBuilderBearer);
          await userContext.setUserData(data);
          setFields(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        } catch (e) {
          navigation("/");
          //TODO Error Logout overloy
        }
      } else {
        //TODO  Logout overloy
        navigation("/");
      }
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      setFields(userContext.userData);
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  const LeftNav = (
    <div id="LeftNav">
      <div id="welcome-sec">
        <h3>Manage Account</h3>
      </div>
      <div id="btn-sec">
        <Button
          className={"nav-btn"}
          variant="light"
          onClick={() => {
            navigation("/dashboard");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={faHome} id="navIcon" /> Dashboard
        </Button>
        {/* <Button
          className={"nav-btn"}
          variant="light"
          onClick={() => {
            navigation("/createProgram");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={faPlus} id="navIcon" /> Create
          Program
        </Button> */}
        {/* <Button
          className={"nav-btn active"}
          variant="light"
          onClick={() => {
            navigation("/account");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={faUser} id="navIcon" /> Account
        </Button> */}
        {/* <Button
          className={"nav-btn"}
          variant="light"
          onClick={() => {
            navigation("/manage-users");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={faUserGroup} id="navIcon" /> User
          Management
        </Button> */}
      </div>
    </div>
  );

  return (
    <>
      {isLoading && <Loader />}
      <Nav path={"dashboard"} />
      <Container fluid style={{ marginTop: 100 }}>
        <Row>
          <Col lg={1}></Col>
          <Col lg={11}>
            <div className="d-flex align-items-center">
              <Button
                variant="light"
                onClick={() => {
                  navigation("/dashboard");
                }}
              >
                <FontAwesomeIcon size={"xl"} icon={faHome} id="navIcon" />
              </Button>

              <FontAwesomeIcon
                className="ml-3 mr-3"
                size={"xl"}
                icon={faChevronRight}
                id="navIcon"
              />
              <h3>Manage Account</h3>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row style={{ minHeight: "100vh" }}>
          <Col lg={2} />
          <Col lg={8} className="mr-auto d-flex justify-content-center">
            <div id="accountDetails">
              <div>
                <h1>Account Details</h1>
              </div>
              <div>
                <Form.Group controlId="name">
                  <Form.Label> {isAdmin ? "Name" : "Family Name"}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    defaultValue={name}
                    required
                    disabled={isLoading}
                  />
                </Form.Group>
                <Form.Group controlId="emailAddress">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email Address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    defaultValue={email}
                    disabled={isLoading}
                    required
                  />
                </Form.Group>
                {doResetPassword && (
                  <>
                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder=""
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        defaultValue={password}
                        disabled={isLoading}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="password2">
                      <Form.Label>Re-enter Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Re-enter Password"
                        onChange={(e) => {
                          setPass2(e.target.value);
                        }}
                        defaultValue={pass2}
                        disabled={isLoading}
                        required
                      />
                    </Form.Group>
                  </>
                )}
                {!isAdmin && (
                  <Form.Group controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      defaultValue={phoneNumber}
                      disabled={isLoading}
                      required
                    />
                  </Form.Group>
                )}
                {!isAdmin && (
                  <Form.Group controlId="funeralDate">
                    <Form.Label>Funeral Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Funeral Date"
                      onChange={(e) => {
                        setFuneralDate(e.target.value);
                      }}
                      defaultValue={funeralDate}
                      disabled={isLoading}
                      required
                    />
                  </Form.Group>
                )}
                <Form.Group
                  className="text-center"
                  style={{ minHeight: 25, color: "red" }}
                >
                  <p>{error}</p>
                </Form.Group>
                <Form.Group className="text-right">
                  <Button
                    variant={doResetPassword ? "dark" : "light"}
                    onClick={() => {
                      setDoResetPassword(!doResetPassword);
                    }}
                    className="mr-2 ml-2"
                  >
                    {doResetPassword
                      ? "Close Password Reset"
                      : "Need to reset your password?"}
                  </Button>
                  <Button
                    className="ml-2"
                    onClick={doPatchUser}
                    variant="warning"
                  >
                    Submit
                  </Button>
                </Form.Group>
              </div>
            </div>
          </Col>
          <Col lg={2} />
        </Row>
      </Container>
    </>
  );
};
