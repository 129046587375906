import "./App.css";
import {
  Home,
  Dashboard,
  CreateProgram,
  EditAccount,
  UserManagement,
  Program,
} from "./pages";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { UserContext } from "./context";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  const [userData, setUserData] = useState(null);

  return (
    <div id="App">
      <BrowserRouter>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/createProgram" element={<CreateProgram />} />
            <Route path="/account" element={<EditAccount />} />
            <Route path="/manage-users" element={<UserManagement />} />
            <Route path="/program/:id" element={<Program />} />
          </Routes>
        </UserContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
